import React from 'react';
import styled from 'styled-components';

import { formatCurrencyAmount } from '../../../Locales';
import { AnimatedGradientBackground } from '../../../components/common/GradientBackground';
import { MerchantIdToMerchantNameMap, MerchantProductOffer } from '../../../lib/productDiscovery/conversationMessages';
import { CLICKABLE_ELEMENT_CSS_MIXIN } from '../../../style/styleConstants';
import { colors } from '../../../tokens/theme';
import { font } from '../../../style/text';
import { deviceWidthMeasurements } from '../../../style/size';
import { useProductImage } from '../../../lib/productDiscovery/productImages';

const productLinkArrow = '/assets/images/icons/product-link-arrow.svg';

export function MerchantProductOfferCard({
    offer,
    merchantIdToMerchantNameMap,
}: {
    offer: MerchantProductOffer;
    merchantIdToMerchantNameMap: MerchantIdToMerchantNameMap;
}) {
    const { isImageLoading, imageUrl, onImageLoad, onImageError } = useProductImage(offer);
    if (!offer.processedWebpageUrl) return null;
    const merchantNameToDisplay =
        offer.merchantId && merchantIdToMerchantNameMap[offer.merchantId]
            ? merchantIdToMerchantNameMap[offer.merchantId]
            : offer.contextualInformation.originalMerchantName;
    return (
        <ProductCardContainer onClick={() => window.open(offer.processedWebpageUrl as string, '_blank')}>
            <ProductCardContent>
                {isImageLoading ? (
                    <ProductImageContainer {...{ isImageLoading, isImagePlaceholder: true }}>
                        {/* TODO: refactor to remove React Native components */}
                        <AnimatedGradientBackground />
                    </ProductImageContainer>
                ) : null}
                <ProductImageContainer {...{ isImageLoading }}>
                    {imageUrl ? (
                        <ProductImage src={imageUrl} onLoad={() => onImageLoad()} onError={() => onImageError(imageUrl)} />
                    ) : null}
                </ProductImageContainer>
                <ProductTextContainer>
                    <ProductPriceText>
                        {formatCurrencyAmount(offer.priceInformation.displayPriceAmount.valueInCents / 100)}
                    </ProductPriceText>
                    <ProductTitleText>{offer.originalTitle}</ProductTitleText>
                    {merchantNameToDisplay ? (
                        <ProductLinkContainer>
                            <ProductLinkText>{merchantNameToDisplay}</ProductLinkText>
                            <LinkArrowImage src={productLinkArrow} />
                        </ProductLinkContainer>
                    ) : null}
                </ProductTextContainer>
            </ProductCardContent>
        </ProductCardContainer>
    );
}

export function MerchantProductOfferPlaceholderCard() {
    return (
        <ProductCardContainer isPlaceholder={true}>
            <ProductCardContent>
                <ProductImageContainer isImageLoading={false}>
                    <ProductImagePlaceholder />
                </ProductImageContainer>
                <ProductTextContainer>
                    <ProductPricePlaceholder />
                    <ProductTitlePlaceholder>
                        <RectanglePlaceHolder />
                        <RectanglePlaceHolder />
                    </ProductTitlePlaceholder>
                    <ProductLinkPlaceholder />
                </ProductTextContainer>
            </ProductCardContent>
        </ProductCardContainer>
    );
}

const ProductCardContainer = styled.div<{
    isPlaceholder?: boolean | undefined;
}>`
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    max-width: ${({ isPlaceholder }) => (isPlaceholder ? '100%' : 'max-content')};

    @media (min-width: ${deviceWidthMeasurements.small}) {
        ${({ isPlaceholder }) =>
            !isPlaceholder &&
            `
            ${CLICKABLE_ELEMENT_CSS_MIXIN}
            &:hover {
                z-index: 1;
                background-color: ${colors.background.default};
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
            }
        `}
    }

    @media (max-width: ${deviceWidthMeasurements.small}) {
        max-width: 144px;
        background-color: ${colors.background.default};
    }
`;

const ProductCardContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const ProductImageContainer = styled.div<{ isImageLoading: boolean; isImagePlaceholder?: boolean }>`
    display: ${({ isImageLoading, isImagePlaceholder }) => (isImageLoading && !isImagePlaceholder ? 'none' : 'flex')};
    align-items: center;
    aspect-ratio: 1;
    margin-bottom: 12px;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        width: 144px;
        height: 144px;
    }
`;

const ProductTextContainer = styled.div`
    padding: 0 12px 12px 12px;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        padding: 0;
    }
`;

const ProductLinkContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        margin-top: 4px;
    }
`;

const ProductImage = styled.img`
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    object-fit: contain;
    border-radius: 12px;
`;

const ProductImagePlaceholder = styled.div`
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 12px;
    background-color: ${colors.background.subtle};
`;

const LinkArrowImage = styled.img`
    width: 11px;
    height: 11px;
    object-fit: contain;
`;

const ProductPriceText = styled.div`
    font-size: 18px;
    font-family: ${font.ambitBold};

    @media (max-width: ${deviceWidthMeasurements.small}) {
        font-size: 16px;
    }
`;

const ProductPricePlaceholder = styled.div`
    justify-content: left;
    width: 40%;
    height: 20px;
    border-radius: 20px;
    background-color: ${colors.background.subtle};
`;

const ProductTitleText = styled.div`
    display: -webkit-box;
    margin-top: 8px;
    font-size: 16px;
    font-family: ${font.ambitSemiBold};
    color: ${colors.content.secondary};
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        font-size: 14px;
    }
`;

const ProductTitlePlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 8px;
    gap: 6px;
`;

const RectanglePlaceHolder = styled.div`
    width: 100%;
    height: 12px;
    border-radius: 20px;
    background-color: ${colors.background.subtle};
`;

const ProductLinkText = styled.div`
    display: -webkit-box;
    margin-right: 8px;
    font-size: 14px;
    font-family: ${font.ambitSemiBold};
    color: ${colors.content.secondary};
    text-decoration-line: underline;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`;

const ProductLinkPlaceholder = styled.div`
    width: 50%;
    height: 16px;
    margin-top: 8px;
    border-radius: 20px;
    background-color: ${colors.background.subtle};
`;
