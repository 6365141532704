import React from 'react';
import styled from 'styled-components';

import { UserProductDiscoveryConversationHistoryItem } from '../../../api/graphql/fragments/productDiscovery';
import { ProductDiscoveryConversationMessageWithProducts } from '../../../lib/productDiscovery/conversationMessages';
import { Overlay } from '../common/Overlay';
import { ConversationHistorySection } from '../common/ConversationHistorySection';
import { Header } from '../common/Header';
import { LandingPageContent } from '../common/LandingPageContent';
import { MobileMessagesSection } from './MobileMessagesSection';
import { InputBar } from '../common/InputBar';
import { ErrorMessageOnFetchConversation } from '../common/ErrorMessage';
import { useViewportSize } from '../../../style/utils';
import { useShouldShowConversationHistory } from '../../../lib/productDiscovery/conversationHistory';

export function MobileProductDiscoveryPage({
    conversationId,
    resetConversation,
    messages,
    setMessages,
    messagesListRef,
    inputText,
    setInputText,
    selectedConversationHistoryItemId,
    setSelectedConversationHistoryItemId,
    failedToFetchConversation,
    setFailedToFetchConversation,
    sendMessage,
    isAssistantThinking,
    lastConversationHistoryItem,
    lastConversationTitle,
}: {
    conversationId: string;
    resetConversation: ({
        conversationToReopen,
    }: {
        conversationToReopen?: {
            conversationId: string;
            messages: ProductDiscoveryConversationMessageWithProducts[];
        };
    }) => void;
    messages: ProductDiscoveryConversationMessageWithProducts[] | undefined;
    setMessages: (messages: ProductDiscoveryConversationMessageWithProducts[]) => void;
    messagesListRef: React.RefObject<HTMLDivElement | null>;
    inputText: string;
    setInputText: (value: string) => void;
    selectedConversationHistoryItemId: string | undefined;
    setSelectedConversationHistoryItemId: (value: string | undefined) => void;
    failedToFetchConversation: boolean;
    setFailedToFetchConversation: (value: boolean) => void;
    sendMessage: (suggestedMessage?: string) => void;
    isAssistantThinking: boolean;
    lastConversationHistoryItem: UserProductDiscoveryConversationHistoryItem | undefined;
    lastConversationTitle: string | undefined;
}) {
    const { height: viewportHeight } = useViewportSize();
    const inputTextRef = React.useRef<HTMLTextAreaElement>(null);
    const { shouldShowConversationHistory, setShouldShowConversationHistory } = useShouldShowConversationHistory();
    const [shouldShowInfoTooltip, setShouldShowInfoTooltip] = React.useState(false);
    const [isInputBarFocused, setIsInputBarFocused] = React.useState(false);
    usePreventWebpageScroll(shouldShowConversationHistory);
    const [bottomPositionScrollButton, setBottomPositionScrollButton] = React.useState(0);
    const shouldDisplayOverlay = shouldShowConversationHistory || shouldShowInfoTooltip;
    const handleOverlayClick = () => {
        setShouldShowInfoTooltip(false);
        setShouldShowConversationHistory(false);
    };
    return (
        <>
            <Overlay {...{ isVisible: shouldDisplayOverlay }} onClick={handleOverlayClick} />
            <MainContainer {...{ viewportHeight }}>
                <ConversationHistorySection
                    {...{
                        conversationId,
                        resetConversation,
                        setMessages,
                        inputTextRef,
                        shouldShowConversationHistory,
                        setShouldShowConversationHistory,
                        selectedConversationHistoryItemId,
                        setSelectedConversationHistoryItemId,
                        setFailedToFetchConversation,
                        lastConversationHistoryItem,
                        lastConversationTitle,
                    }}
                />
                <ContentContainer>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                        <Header
                            {...{
                                resetConversation,
                                conversationId,
                                shouldShowConversationHistory,
                                setShouldShowConversationHistory,
                                shouldShowInfoTooltip,
                                setShouldShowInfoTooltip,
                                inputTextRef,
                            }}
                        />
                        {!failedToFetchConversation ? (
                            <>
                                {messages === undefined ? (
                                    <LandingPageContent {...{ sendMessage, isInputBarFocused }} />
                                ) : (
                                    <MobileMessagesSection
                                        {...{ messages, messagesListRef, conversationId, bottomPositionScrollButton }}
                                    />
                                )}
                                <InputBar
                                    {...{
                                        inputText,
                                        setInputText,
                                        inputTextRef,
                                        setIsInputBarFocused,
                                        sendMessage,
                                        isAssistantThinking,
                                        setBottomPositionScrollButton,
                                    }}
                                />
                            </>
                        ) : (
                            <ErrorMessageOnFetchConversation />
                        )}
                    </div>
                </ContentContainer>
            </MainContainer>
        </>
    );
}

function usePreventWebpageScroll(shouldShowConversationHistory: boolean) {
    React.useEffect(() => {
        if (shouldShowConversationHistory) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = '';
    }, [shouldShowConversationHistory]);
}

const MainContainer = styled.div<{
    viewportHeight: number;
}>`
    display: flex;
    flex-direction: row;
    height: ${({ viewportHeight }) => viewportHeight}px;
    position: relative;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
`;
