import React from 'react';

import { getApolloClient } from '../../api/config';
import { ApolloClient } from '../../api/graphql/client';
import { Feature, useHasFeature } from '../features/buildFeatureMap';
import { getMerchantName } from '../merchants/merchants';
import { getWebSocketApiUrl } from './websockets';
import { getLocaleCode, getLocalizedTexts } from '../../Locales';
import {
    updateProductDiscoveryConversationMessageWithProductsListInCache,
    updateProductDiscoveryConversationsQueryInCache,
} from './conversationHistory';
import { UserProductDiscoveryConversationHistoryItem } from '../../api/graphql/fragments/productDiscovery';

type ProductDiscoveryConversationMessage = {
    messagePosition: string;
    content: string;
    role: 'system' | 'user' | 'assistant';
    timestamp: number;
};
export interface MerchantProductOffer {
    merchantId: string | null;
    merchantProductOfferId: string | null;
    originalTitle: string;
    processedWebpageUrl: string | null;
    mainImageUrls: MerchantProductOfferImageUrls;
    alternateImagesUrls?: MerchantProductOfferImageUrls[];
    priceInformation: {
        displayPriceAmount: {
            currency: string;
            valueInCents: number;
        };
    };
    contextualInformation: {
        originalMerchantName: string | null;
    };
}

export type MerchantProductOfferImageUrls = {
    defaultSize: string;
};

export type ProductDiscoveryConversationMessageWithProducts = ProductDiscoveryConversationMessage & {
    merchantProductOffers?: MerchantProductOffer[];
};

type ConversationIdMessage = {
    messageType: 'conversationId';
    payload: {
        conversationId: string;
    };
};

type ConversationFromUserSearchQueryMessage = {
    messageType: 'conversationFromUserSearchQuery';
    payload: {
        conversationId: string;
        merchantProductOffers: MerchantProductOffer[];
    };
};

type StreamedAssistantMessage = {
    messageType: 'streamedAssistantMessage';
    payload: {
        conversationId: string;
        assistantMessageChunk: string;
    };
};

type FullAssistantMessage = {
    messageType: 'fullAssistantMessage';
    payload: {
        conversationId: string;
        content: string;
        merchantProductOffers: MerchantProductOffer[];
    };
};

type ConversationTitleMessage = {
    messageType: 'conversationTitle';
    payload: {
        conversationId: string;
        conversationTitle: string;
        createdAt: string;
    };
};

type GetProductDiscoveryConversationIdPayload = {
    action: 'getProductDiscoveryConversationId';
    userId: string | undefined;
};

type CloseProductDiscoveryConversationPayload = {
    action: 'closeProductDiscoveryConversation';
    userId: string | undefined;
    conversationId: string;
};

type StreamProductDiscoveryMessagePayload = {
    action: 'streamProductDiscoveryMessage';
    userId: string | undefined;
    timestamp: number;
    conversationId: string;
    message: string;
    localeCode: string;
    shouldLogAllData: boolean;
};

type CreateConversationFromUserSearchQueryPayload = {
    action: 'createConversationFromUserSearchQuery';
    userId: string;
    userSearchQuery: string;
    assistantMessageContent: string;
    partialMerchantProductOffers: {
        merchantProductOfferId: string;
        priceInformation: {
            displayPriceAmount: {
                valueInCents: number;
                currency: string;
            };
        };
    }[];
};

type WebSocketMessagePayload =
    | GetProductDiscoveryConversationIdPayload
    | CloseProductDiscoveryConversationPayload
    | StreamProductDiscoveryMessagePayload
    | CreateConversationFromUserSearchQueryPayload;

function sendWebSocketMessage({ webSocket, payload }: { webSocket: WebSocket; payload: WebSocketMessagePayload }) {
    if (webSocket.readyState === WebSocket.OPEN) webSocket.send(JSON.stringify(payload));
}

export function useHandleConnectionWithAssistant({
    userId,
    webSocketRef,
    messageCounterRef,
    setIsWebSocketConnectionOpen,
    messages,
    setMessages,
    setIsAssistantThinking,
    conversationId,
    setConversationId,
    partialMerchantProductOffers,
    setLastConversationHistoryItem,
    setLastConversationTitle,
}: {
    userId: string | undefined;
    webSocketRef: React.MutableRefObject<WebSocket | undefined>;
    messageCounterRef: React.MutableRefObject<number>;
    setIsWebSocketConnectionOpen: (value: boolean) => void;
    messages: ProductDiscoveryConversationMessageWithProducts[] | undefined;
    setMessages: React.Dispatch<React.SetStateAction<ProductDiscoveryConversationMessageWithProducts[] | undefined>>;
    setIsAssistantThinking: (value: boolean) => void;
    conversationId: string;
    setConversationId: (value: string) => void;
    partialMerchantProductOffers: MerchantProductOffer[];
    setLastConversationHistoryItem: (value: UserProductDiscoveryConversationHistoryItem | undefined) => void;
    setLastConversationTitle: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
    const isSandboxEnvironment = !!useHasFeature(Feature.useProductDiscoverySandboxEnvironment);
    const currentAssistantMessageRef = React.useRef<ProductDiscoveryConversationMessageWithProducts | undefined>(undefined);
    useCloseWebSocketOnUnmount({ userId, conversationId, webSocketRef });
    /**
     * If the web socket is not connected when the user tries to send a message for some reason,
     * we initialize it again and rely on the `payloadToSendOnWebSocketOpen` to send the message right after the connection is open.
     */
    function initializeWebSocket({
        shouldGetNewConversationId,
        payloadToSendOnWebSocketOpen,
    }: {
        shouldGetNewConversationId?: boolean;
        payloadToSendOnWebSocketOpen?: WebSocketMessagePayload;
    }) {
        if (!userId) return;
        if (isSandboxEnvironment) console.log('Initializing WebSocket connection...');
        const websocketUrl = getWebSocketApiUrl();
        const webSocket = new WebSocket(websocketUrl + '?userId=' + userId);
        webSocketRef.current = webSocket;
        webSocket.onopen = () =>
            handleWebSocketOpen({
                webSocket,
                setIsWebSocketConnectionOpen,
                shouldGetNewConversationId,
                payloadToSendOnWebSocketOpen,
                userId,
                isSandboxEnvironment,
            });
        webSocket.onmessage = (event: MessageEvent) =>
            handleWebSocketMessage({
                event,
                isSandboxEnvironment,
                setConversationId,
                currentAssistantMessageRef,
                messageCounterRef,
                setMessages,
                setIsAssistantThinking,
                setLastConversationHistoryItem,
                setLastConversationTitle,
            });
        webSocket.onerror = (error) => {
            if (isSandboxEnvironment) console.error('WebSocket error:', error);
        };
        webSocket.onclose = (event: CloseEvent) => handleWebSocketClose({ event, isSandboxEnvironment });
    }
    function closeWebSocket({ conversationIdToClose }: { conversationIdToClose?: string }) {
        if (webSocketRef.current) {
            if (conversationIdToClose) {
                sendWebSocketMessage({
                    webSocket: webSocketRef.current,
                    payload: {
                        action: 'closeProductDiscoveryConversation',
                        userId,
                        conversationId: conversationIdToClose,
                    },
                });
                setConversationId('');
            }
            // We add a 1000 code to the close method to indicate a normal closure
            webSocketRef.current.close(1000);
        }
    }
    React.useEffect(() => {
        if (!userId) return;
        if (partialMerchantProductOffers.length) {
            // This happens when the user performs a search query and is redirected from the extension
            if (isSandboxEnvironment) console.log('Initializing WebSocket connection from URL params...');
            setIsAssistantThinking(true);
            if (!messages || messages.length < 2) {
                if (isSandboxEnvironment)
                    console.error(
                        'Messages are not defined or do not contain the user search query and the assistant message.'
                    );
                window.history.pushState({}, '', `/product-discovery`);
                return;
            }
            initializeWebSocket({
                shouldGetNewConversationId: false,
                payloadToSendOnWebSocketOpen: createPayloadToSendWhenFromGoogleSearchQuery(
                    userId,
                    messages,
                    partialMerchantProductOffers
                ),
            });
        } else initializeWebSocket({ shouldGetNewConversationId: true });
    }, [userId]);
    return { initializeWebSocket, closeWebSocket };
}

function createPayloadToSendWhenFromGoogleSearchQuery(
    userId: string,
    messages: ProductDiscoveryConversationMessageWithProducts[],
    merchantProductOffers: MerchantProductOffer[]
): CreateConversationFromUserSearchQueryPayload {
    const partialMerchantProductOffers =
        merchantProductOffers.map((offer) => ({
            merchantProductOfferId: offer.merchantProductOfferId as string,
            priceInformation: offer.priceInformation,
        })) ?? [];
    const payload: WebSocketMessagePayload = {
        action: 'createConversationFromUserSearchQuery',
        userId,
        userSearchQuery: messages[0].content,
        assistantMessageContent: messages[1].content,
        partialMerchantProductOffers,
    };
    return payload;
}

function useCloseWebSocketOnUnmount({
    userId,
    conversationId,
    webSocketRef,
}: {
    userId: string | undefined;
    conversationId: string;
    webSocketRef: React.MutableRefObject<WebSocket | undefined>;
}) {
    React.useEffect(() => {
        if (!userId || !conversationId) return;
        const handleCloseConversationBeforeUnload = () => {
            if (webSocketRef.current?.readyState === WebSocket.OPEN) {
                sendWebSocketMessage({
                    webSocket: webSocketRef.current,
                    payload: {
                        action: 'closeProductDiscoveryConversation',
                        userId,
                        conversationId,
                    },
                });
                // We add a 1000 code to the close method to indicate a normal closure
                webSocketRef.current.close(1000);
            }
        };
        window.addEventListener('beforeunload', () => handleCloseConversationBeforeUnload());
    }, [userId, conversationId]);
}

function handleWebSocketOpen({
    webSocket,
    setIsWebSocketConnectionOpen,
    shouldGetNewConversationId,
    payloadToSendOnWebSocketOpen,
    userId,
    isSandboxEnvironment,
}: {
    webSocket: WebSocket;
    setIsWebSocketConnectionOpen: (value: boolean) => void;
    shouldGetNewConversationId?: boolean;
    payloadToSendOnWebSocketOpen?: WebSocketMessagePayload;
    userId: string;
    isSandboxEnvironment: boolean;
}) {
    setIsWebSocketConnectionOpen(true);
    if (payloadToSendOnWebSocketOpen) {
        // If we have a `payloadToSendOnWebSocketOpen` it means we want to continue the conversation with the same `conversationId`
        sendWebSocketMessage({ webSocket, payload: payloadToSendOnWebSocketOpen });
        return;
    }
    if (shouldGetNewConversationId) {
        if (isSandboxEnvironment) console.log('Fetching a new conversation ID...');
        sendWebSocketMessage({
            webSocket,
            payload: {
                action: 'getProductDiscoveryConversationId',
                userId,
            },
        });
    }
}

async function handleWebSocketMessage({
    event,
    isSandboxEnvironment,
    setConversationId,
    currentAssistantMessageRef,
    messageCounterRef,
    setMessages,
    setIsAssistantThinking,
    setLastConversationHistoryItem,
    setLastConversationTitle,
}: {
    event: MessageEvent;
    isSandboxEnvironment: boolean;
    setConversationId: (value: string) => void;
    currentAssistantMessageRef: React.MutableRefObject<ProductDiscoveryConversationMessageWithProducts | undefined>;
    messageCounterRef: React.MutableRefObject<number>;
    setMessages: React.Dispatch<React.SetStateAction<ProductDiscoveryConversationMessageWithProducts[] | undefined>>;
    setIsAssistantThinking: (value: boolean) => void;
    setLastConversationHistoryItem: (value: UserProductDiscoveryConversationHistoryItem | undefined) => void;
    setLastConversationTitle: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
    const webSocketMessage = JSON.parse(event.data);
    const messageType = webSocketMessage.messageType;
    switch (messageType) {
        case 'conversationId':
            handleConversationIdMessage({ webSocketMessage, setConversationId, isSandboxEnvironment });
            break;
        case 'conversationFromUserSearchQuery':
            handleConversationFromUserSearchQueryMessage({
                webSocketMessage,
                setMessages,
                messageCounterRef,
                setConversationId,
                setIsAssistantThinking,
                isSandboxEnvironment,
            });
            break;
        case 'streamedAssistantMessage':
            handleStreamedAssistantMessage({
                webSocketMessage,
                currentAssistantMessageRef,
                messageCounterRef,
                setMessages,
                setLastConversationHistoryItem,
                isSandboxEnvironment,
            });
            break;
        case 'fullAssistantMessage':
            handleFullAssistantMessage({
                webSocketMessage,
                currentAssistantMessageRef,
                setMessages,
                setIsAssistantThinking,
                isSandboxEnvironment,
            });
            break;
        case 'conversationTitle':
            handleConversationTitleMessage({
                webSocketMessage,
                setLastConversationHistoryItem,
                setLastConversationTitle,
                isSandboxEnvironment,
            });
            break;
        default:
            if (isSandboxEnvironment) console.warn('Unknown message type:', messageType);
    }
}

function handleConversationIdMessage({
    webSocketMessage,
    setConversationId,
    isSandboxEnvironment,
}: {
    webSocketMessage: ConversationIdMessage;
    setConversationId: (value: string) => void;
    isSandboxEnvironment: boolean;
}) {
    const { payload } = webSocketMessage;
    if (!payload?.conversationId) {
        if (isSandboxEnvironment) console.warn(`Unexpected payload for message ${webSocketMessage.messageType}:`, payload);
        return;
    }
    setConversationId(payload.conversationId);
    if (isSandboxEnvironment) console.log('Received product discovery conversation ID:', payload.conversationId);
}

function handleConversationFromUserSearchQueryMessage({
    webSocketMessage,
    setMessages,
    messageCounterRef,
    setConversationId,
    setIsAssistantThinking,
    isSandboxEnvironment,
}: {
    webSocketMessage: ConversationFromUserSearchQueryMessage;
    setMessages: React.Dispatch<React.SetStateAction<ProductDiscoveryConversationMessageWithProducts[] | undefined>>;
    messageCounterRef: React.MutableRefObject<number>;
    setConversationId: (value: string) => void;
    setIsAssistantThinking: (value: boolean) => void;
    isSandboxEnvironment: boolean;
}) {
    const { payload } = webSocketMessage;
    if (!payload?.conversationId || !payload?.merchantProductOffers) {
        if (isSandboxEnvironment) console.warn(`Unexpected payload for message ${webSocketMessage.messageType}:`, payload);
        return;
    }
    setMessages((prevMessages) =>
        prevMessages?.map((message) =>
            message.messagePosition === '1'
                ? {
                      ...message,
                      merchantProductOffers: payload.merchantProductOffers,
                  }
                : message
        )
    );
    messageCounterRef.current = 1;
    setConversationId(payload.conversationId);
    setIsAssistantThinking(false);
    // We use a `replaceState` so that if the user makes a "go back" we won't redirect to the custom URL containing all the initial information from the search query
    window.history.replaceState({}, '', `/product-discovery/${payload.conversationId}`);
}

/**
 * The API sends back either chunks of a message when sending back the streamed assistant response,
 * or the full message when the assistant has finished processing the user's message. The full message also
 * contains the merchant product offers, that we use to display the products within the conversation.
 */
async function handleStreamedAssistantMessage({
    webSocketMessage,
    currentAssistantMessageRef,
    messageCounterRef,
    setMessages,
    setLastConversationHistoryItem,
    isSandboxEnvironment,
}: {
    webSocketMessage: StreamedAssistantMessage;
    currentAssistantMessageRef: React.MutableRefObject<ProductDiscoveryConversationMessageWithProducts | undefined>;
    messageCounterRef: React.MutableRefObject<number>;
    setMessages: React.Dispatch<React.SetStateAction<ProductDiscoveryConversationMessageWithProducts[] | undefined>>;
    setLastConversationHistoryItem: (value: UserProductDiscoveryConversationHistoryItem | undefined) => void;
    isSandboxEnvironment: boolean;
}) {
    const { payload } = webSocketMessage;
    if (!payload?.conversationId || !payload?.assistantMessageChunk) {
        if (isSandboxEnvironment) console.warn(`Unexpected payload for message ${webSocketMessage.messageType}:`, payload);
        return;
    }
    if (currentAssistantMessageRef.current === undefined) {
        const assistantMessage: ProductDiscoveryConversationMessageWithProducts = {
            messagePosition: messageCounterRef.current.toString(),
            timestamp: Date.now(),
            content: payload.assistantMessageChunk,
            role: 'assistant',
        };
        if (messageCounterRef.current === 1 && payload.conversationId) {
            const newConversationTitlePlaceholder = getLocalizedTexts().productDiscovery.newConversationTitlePlaceholder;
            // We use the current date as a placeholder until we receive the real date with the "conversationTitle" message
            const createdAtPlaceholder = new Date().toISOString();
            setLastConversationHistoryItem({
                __typename: 'UserProductDiscoveryConversationHistoryItem',
                conversationId: payload.conversationId,
                createdAt: createdAtPlaceholder,
                conversationTitle: newConversationTitlePlaceholder,
            });
            const apolloClient = await getApolloClient();
            if (apolloClient && payload.conversationId) {
                updateProductDiscoveryConversationsQueryInCache({
                    apolloClient,
                    conversationId: payload.conversationId,
                    createdAt: createdAtPlaceholder,
                    isSandboxEnvironment,
                });
                // We wait for the first chunk of the assistant message to update the URL so that we know the backend has processed the user's message
                window.history.pushState({}, '', `/product-discovery/${payload.conversationId}`);
            }
        }
        currentAssistantMessageRef.current = assistantMessage;
        setMessages((prevMessages) =>
            prevMessages?.map((message) =>
                message.messagePosition === currentAssistantMessageRef.current?.messagePosition ? assistantMessage : message
            )
        );
    } else {
        const updatedMessage = {
            ...currentAssistantMessageRef.current,
            content: (currentAssistantMessageRef.current.content || '') + payload.assistantMessageChunk,
        };
        currentAssistantMessageRef.current = updatedMessage;
        setMessages((prevMessages) =>
            prevMessages?.map((message) =>
                message.messagePosition === currentAssistantMessageRef.current?.messagePosition ? updatedMessage : message
            )
        );
    }
}

async function handleFullAssistantMessage({
    webSocketMessage,
    currentAssistantMessageRef,
    setMessages,
    setIsAssistantThinking,
    isSandboxEnvironment,
}: {
    webSocketMessage: FullAssistantMessage;
    currentAssistantMessageRef: React.MutableRefObject<ProductDiscoveryConversationMessageWithProducts | undefined>;
    setMessages: React.Dispatch<React.SetStateAction<ProductDiscoveryConversationMessageWithProducts[] | undefined>>;
    setIsAssistantThinking: (value: boolean) => void;
    isSandboxEnvironment: boolean;
}) {
    const { payload } = webSocketMessage;
    if (!payload?.conversationId || !payload?.content || !payload?.merchantProductOffers) {
        if (isSandboxEnvironment) console.warn(`Unexpected payload for message ${webSocketMessage.messageType}:`, payload);
        return;
    }
    if (!currentAssistantMessageRef.current) {
        if (isSandboxEnvironment) console.warn('Received assistant content but no assistant message was found.');
        return;
    }
    const updatedMessage = {
        ...currentAssistantMessageRef.current,
        content: payload.content || currentAssistantMessageRef.current.content || '',
        merchantProductOffers: payload.merchantProductOffers || [],
    };
    currentAssistantMessageRef.current = undefined;
    let newMessages: ProductDiscoveryConversationMessageWithProducts[] | undefined;
    setMessages((prevMessages) => {
        newMessages = prevMessages?.map((message) =>
            message.messagePosition === updatedMessage.messagePosition ? updatedMessage : message
        );
        return newMessages;
    });
    setIsAssistantThinking(false);
    const conversationId = payload.conversationId;
    if (conversationId && newMessages) {
        const apolloClient = await getApolloClient();
        if (!apolloClient) return;
        updateProductDiscoveryConversationMessageWithProductsListInCache(
            apolloClient,
            conversationId,
            isSandboxEnvironment,
            newMessages.map((message) => ({
                ...message,
                __typename: 'UserProductDiscoveryMessageWithProducts',
                messageId: `${conversationId}|${message.messagePosition}`,
                merchantProductOffers: message.merchantProductOffers
                    ? message.merchantProductOffers.map((offer) => {
                          return {
                              __typename: 'MerchantProductOffer',
                              ...offer,
                          };
                      })
                    : [],
            }))
        );
    }
}

async function handleConversationTitleMessage({
    webSocketMessage,
    setLastConversationHistoryItem,
    setLastConversationTitle,
    isSandboxEnvironment,
}: {
    webSocketMessage: ConversationTitleMessage;
    setLastConversationHistoryItem: (value: UserProductDiscoveryConversationHistoryItem | undefined) => void;
    setLastConversationTitle: React.Dispatch<React.SetStateAction<string | undefined>>;
    isSandboxEnvironment: boolean;
}) {
    const { payload } = webSocketMessage;
    if (!payload?.conversationId || !payload?.conversationTitle || !payload?.createdAt) {
        if (isSandboxEnvironment) console.warn(`Unexpected payload for message ${webSocketMessage.messageType}:`, payload);
        return;
    }
    setLastConversationHistoryItem({
        __typename: 'UserProductDiscoveryConversationHistoryItem',
        conversationId: payload.conversationId,
        createdAt: payload.createdAt,
        conversationTitle: payload.conversationTitle,
    });
    const apolloClient = await getApolloClient();
    if (apolloClient)
        updateProductDiscoveryConversationsQueryInCache({
            apolloClient,
            conversationId: payload.conversationId,
            createdAt: payload.createdAt,
            generatedConversationTitle: payload.conversationTitle,
            isSandboxEnvironment,
        });
    // We animate the conversation title by displaying it character by character
    let index = 0;
    const characters = payload.conversationTitle.split('') as string[];
    const intervalId = setInterval(() => {
        setLastConversationTitle((prevConversationTitle) => (prevConversationTitle || '') + characters[index]);
        index++;
        if (index === characters.length) clearInterval(intervalId);
    }, 30);
}

function handleWebSocketClose({ event, isSandboxEnvironment }: { event: CloseEvent; isSandboxEnvironment: boolean }) {
    if (isSandboxEnvironment) {
        if (event.code === 1000) console.log(`WebSocket closed with code: ${event.code}`);
        else console.error(`WebSocket closed with an the unexpected code: ${event.code}`);
    }
}

export function useSendMessage({
    userId,
    webSocketRef,
    isWebSocketConnectionOpen,
    messageCounterRef,
    messages,
    setMessages,
    conversationId,
    inputText,
    setInputText,
    initializeWebSocket,
    messagesListRef,
    setIsAssistantThinking,
    firstMessageToSend,
    setFirstMessageToSend,
}: {
    userId: string | undefined;
    webSocketRef: React.MutableRefObject<WebSocket | undefined>;
    isWebSocketConnectionOpen: boolean;
    messageCounterRef: React.MutableRefObject<number>;
    messages: ProductDiscoveryConversationMessageWithProducts[] | undefined;
    setMessages: React.Dispatch<React.SetStateAction<ProductDiscoveryConversationMessageWithProducts[] | undefined>>;
    conversationId: string;
    inputText: string;
    setInputText: React.Dispatch<React.SetStateAction<string>>;
    initializeWebSocket: ({
        shouldGetNewConversationId,
        payloadToSendOnWebSocketOpen,
    }: {
        shouldGetNewConversationId?: boolean;
        payloadToSendOnWebSocketOpen?: WebSocketMessagePayload;
    }) => void;
    messagesListRef: React.MutableRefObject<HTMLDivElement | null>;
    setIsAssistantThinking: (value: boolean) => void;
    firstMessageToSend: string | undefined;
    setFirstMessageToSend: (value: string) => void;
}) {
    const isSandboxEnvironment = !!useHasFeature(Feature.useProductDiscoverySandboxEnvironment);
    const localeCode = React.useMemo(() => getLocaleCode(), []);
    useSendMessageOnConversationInit({
        userId,
        webSocketRef,
        messageCounterRef,
        isWebSocketConnectionOpen,
        messages,
        setMessages,
        conversationId,
        firstMessageToSend,
        localeCode,
        isSandboxEnvironment,
        setInputText,
        setIsAssistantThinking,
    });
    const sendMessage = (suggestedMessage?: string | undefined) => {
        const messageToSend = suggestedMessage ? suggestedMessage : inputText;
        if (messageToSend.trim().length === 0) return;
        if (!conversationId || !webSocketRef.current || !isWebSocketConnectionOpen) {
            // If the `conversationId` is not created yet, we store the message and send it as soon as the connection is open
            setFirstMessageToSend(messageToSend);
            return;
        }
        const userMessagePosition = messageCounterRef.current === 0 ? '0' : generateUniqueMessagePosition(messageCounterRef);
        const payload: WebSocketMessagePayload = {
            action: 'streamProductDiscoveryMessage',
            userId,
            timestamp: Date.now(),
            conversationId,
            message: messageToSend,
            localeCode,
            shouldLogAllData: true,
        };
        const userMessage: ProductDiscoveryConversationMessageWithProducts = {
            messagePosition: userMessagePosition,
            timestamp: Date.now(),
            content: messageToSend,
            role: 'user',
        };
        const assistantMessagePosition = generateUniqueMessagePosition(messageCounterRef);
        setMessages((prevMessages) => [
            ...(prevMessages || []),
            userMessage,
            {
                messagePosition: assistantMessagePosition,
                content: '',
                role: 'assistant',
                timestamp: Date.now(),
            },
        ]);
        // If the web socket is not connected when the user tries to send a message, we force a reconnection and send the message right after
        if (webSocketRef.current?.readyState !== WebSocket.OPEN) {
            if (isSandboxEnvironment) console.log('WebSocket connection is not open. Reconnecting...');
            initializeWebSocket({ shouldGetNewConversationId: false, payloadToSendOnWebSocketOpen: payload });
        } else sendWebSocketMessage({ webSocket: webSocketRef.current, payload });
        setInputText('');
        setIsAssistantThinking(true);
        setTimeout(
            () => messagesListRef.current?.scrollTo({ top: messagesListRef.current.scrollHeight, behavior: 'smooth' }),
            100
        ); // We scroll to the end of the list after a delay to ensure the message is rendered
    };
    return sendMessage;
}

/**
 * If the first message has been entered by the user before the connection was initialized, we send it as soon the connection is open.
 * To do so, this hook relies on the `firstMessageToSend` and the `conversationId` states:
 * - If `conversationId` is not yet initialized when the first message was entered, the message is saved in `firstMessageToSend`,
 *   allowing this effect to fake the sending of the message, waiting for the conversation ID to be created.
 * - When `conversationId` is initialized, this effect re-renders and sends the first message immediately.
 */
function useSendMessageOnConversationInit({
    userId,
    webSocketRef,
    isWebSocketConnectionOpen,
    messageCounterRef,
    messages,
    setMessages,
    conversationId,
    firstMessageToSend,
    localeCode,
    isSandboxEnvironment,
    setInputText,
    setIsAssistantThinking,
}: {
    userId: string | undefined;
    webSocketRef: React.MutableRefObject<WebSocket | undefined>;
    isWebSocketConnectionOpen: boolean;
    messageCounterRef: React.MutableRefObject<number>;
    messages: ProductDiscoveryConversationMessageWithProducts[] | undefined;
    setMessages: React.Dispatch<React.SetStateAction<ProductDiscoveryConversationMessageWithProducts[] | undefined>>;
    conversationId: string;
    firstMessageToSend: string | undefined;
    localeCode: string;
    isSandboxEnvironment: boolean;
    setInputText: (value: string) => void;
    setIsAssistantThinking: (value: boolean) => void;
}) {
    React.useEffect(() => {
        if (!firstMessageToSend) return;
        if (conversationId && webSocketRef.current && isWebSocketConnectionOpen) {
            // As soon as the `conversationId` is initialized, we send the first message
            const payload: WebSocketMessagePayload = {
                action: 'streamProductDiscoveryMessage',
                userId,
                timestamp: Date.now(),
                conversationId,
                message: firstMessageToSend,
                localeCode,
                shouldLogAllData: true,
            };
            generateUniqueMessagePosition(messageCounterRef);
            if (isSandboxEnvironment) console.log('Sending the first message after connection was initialized');
            sendWebSocketMessage({ webSocket: webSocketRef.current, payload });
            return;
        }
        if (isSandboxEnvironment) console.log('The first message has been entered before the connection was initialized');
        const userMessage: ProductDiscoveryConversationMessageWithProducts = {
            messagePosition: messageCounterRef.current.toString(),
            timestamp: Date.now(),
            content: firstMessageToSend,
            role: 'user',
        };
        const newAssistantMessagePosition = (messageCounterRef.current + 1).toString();
        if (messages && messages[messages.length - 1]?.messagePosition === newAssistantMessagePosition) return;
        setMessages((prevMessages) => [
            ...(prevMessages || []),
            userMessage,
            {
                messagePosition: newAssistantMessagePosition,
                content: '',
                role: 'assistant',
                timestamp: Date.now(),
            },
        ]);
        setInputText('');
        setIsAssistantThinking(true);
    }, [conversationId, firstMessageToSend, isWebSocketConnectionOpen]);
}

function generateUniqueMessagePosition(messageCounterRef: React.MutableRefObject<number>) {
    messageCounterRef.current += 1;
    return messageCounterRef.current.toString();
}

export function useResetConversation({
    conversationId,
    setMessages,
    messageCounterRef,
    messagesListRef,
    setIsAssistantThinking,
    setFirstMessageToSend,
    setConversationId,
    initializeWebSocket,
    closeWebSocket,
    setIsAtBottom,
    setUserFeedbackMap,
    setSelectedConversationHistoryItemId,
    setFailedToFetchConversation,
    setInputText,
    setLastConversationHistoryItem,
    setLastConversationTitle,
}: {
    conversationId: string;
    setMessages: React.Dispatch<React.SetStateAction<ProductDiscoveryConversationMessageWithProducts[] | undefined>>;
    messageCounterRef: React.MutableRefObject<number>;
    messagesListRef: React.MutableRefObject<HTMLDivElement | null>;
    setIsAssistantThinking: (value: boolean) => void;
    setFirstMessageToSend: (value: string | undefined) => void;
    setConversationId: (value: string) => void;
    initializeWebSocket: ({
        shouldGetNewConversationId,
        payloadToSendOnWebSocketOpen,
    }: {
        shouldGetNewConversationId?: boolean;
        payloadToSendOnWebSocketOpen?: WebSocketMessagePayload;
    }) => void;
    closeWebSocket: ({ conversationIdToClose }: { conversationIdToClose?: string }) => void;
    setIsAtBottom?: (value: boolean) => void; // We keep this optional to keep the compatibility with the previous design of the product discovery page
    setUserFeedbackMap?: (value: { [id: string]: 'positive' | 'negative' | null }) => void;
    setSelectedConversationHistoryItemId: (value: string | undefined) => void;
    setFailedToFetchConversation: (value: boolean) => void;
    setInputText: (value: string) => void;
    setLastConversationHistoryItem: (value: UserProductDiscoveryConversationHistoryItem | undefined) => void;
    setLastConversationTitle: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
    const isSandboxEnvironment = !!useHasFeature(Feature.useProductDiscoverySandboxEnvironment);
    return React.useCallback(
        ({
            conversationToReopen,
        }: {
            conversationToReopen?: {
                conversationId: string;
                messages: ProductDiscoveryConversationMessageWithProducts[] | undefined;
            };
        }) => {
            if (isSandboxEnvironment)
                console.log(
                    `Resetting conversation:\n conversationIdToClose: ${conversationId} \n conversationIdToReopen: ${conversationToReopen?.conversationId}`
                );
            // We close the web socket connection so that we can open a new one with a new conversation ID
            closeWebSocket({ conversationIdToClose: conversationId });
            if (conversationToReopen && conversationToReopen.messages) {
                window.history.pushState({}, '', `/product-discovery/${conversationToReopen.conversationId}`);
                setConversationId(conversationToReopen.conversationId);
                setMessages(conversationToReopen.messages);
                setTimeout(() => scrollToBottom(messagesListRef, 'instant'), 0); // We scroll to the bottom of the list after a delay to ensure the messages are rendered
                messageCounterRef.current = conversationToReopen.messages.length - 1;
                initializeWebSocket({ shouldGetNewConversationId: false });
            } else {
                window.history.pushState({}, '', `/product-discovery/`);
                setConversationId('');
                setMessages(undefined);
                setSelectedConversationHistoryItemId(undefined);
                messageCounterRef.current = 0;
                initializeWebSocket({ shouldGetNewConversationId: true });
            }
            setFailedToFetchConversation(false);
            setIsAssistantThinking(false);
            setFirstMessageToSend(undefined);
            setIsAtBottom?.(false);
            setUserFeedbackMap?.({});
            setInputText('');
            setLastConversationHistoryItem(undefined);
            setLastConversationTitle(undefined);
        },
        [conversationId]
    );
}

export type MerchantIdToMerchantNameMap = {
    [merchantId: string]: string | undefined;
};

export function useMerchantIdToMerchantNameMap({
    apolloClient,
    messages,
}: {
    apolloClient: ApolloClient | undefined;
    messages: ProductDiscoveryConversationMessageWithProducts[] | undefined;
}) {
    const [merchantIdToMerchantNameMap, setMerchantIdToMerchantNameMap] = React.useState<MerchantIdToMerchantNameMap>({});
    React.useEffect(() => {
        if (!apolloClient || !messages?.length) return;
        const lastSuggestedMerchantProductOffers = messages[messages.length - 1].merchantProductOffers;
        if (!lastSuggestedMerchantProductOffers) return;
        const merchantIdsToFetch = lastSuggestedMerchantProductOffers
            .map((offer) => offer.merchantId)
            .filter(
                (merchantId) => !!merchantId && !merchantIdToMerchantNameMap[merchantId] // We only fetch a merchant name if it doesn't already exist in the map
            );
        const fetchMerchantNames = async () => {
            const merchantNames = await Promise.all(
                merchantIdsToFetch.map((merchantId) => getMerchantName(apolloClient, merchantId as string))
            );
            const newMerchantIdToMerchantNameMap = merchantNames.reduce(
                (newMap: { [merchantId: string]: string | undefined }, { merchantId, merchantName }) => {
                    newMap[merchantId] = merchantName;
                    return newMap;
                },
                {}
            );
            setMerchantIdToMerchantNameMap({ ...merchantIdToMerchantNameMap, ...newMerchantIdToMerchantNameMap });
        };
        fetchMerchantNames();
    }, [messages]);
    return merchantIdToMerchantNameMap;
}

export function useHandleScroll({
    listRef,
    listContent,
    shouldAutoScrollToBottom,
    scrollBehavior = 'smooth',
}: {
    listRef: React.MutableRefObject<HTMLDivElement | null>;
    listContent: unknown;
    /** If this is true, the list will automatically scroll to the bottom when new content is added to make it visible */
    shouldAutoScrollToBottom?: boolean;
    scrollBehavior?: ScrollBehavior;
}) {
    const [isAtBottom, setIsAtBottom] = React.useState(true);
    const previousScrollTopRef = React.useRef(0);
    const shouldDisableAutoScrollingRef = React.useRef(false);
    const onScroll = () => {
        const container = listRef.current;
        if (!container) return;
        const currentScrollTop = container.scrollTop;
        // If the user has scrolled up we disable the auto-scrolling when new content is added to let the user read the previous content
        if (currentScrollTop < previousScrollTopRef.current) shouldDisableAutoScrollingRef.current = true;
        previousScrollTopRef.current = currentScrollTop;
        const isAtBottom = container.scrollTop + container.clientHeight >= container.scrollHeight;
        if (isAtBottom) {
            // We reactivate the auto-scrolling once the user scrolls back to the bottom
            shouldDisableAutoScrollingRef.current = false;
            setIsAtBottom(true);
        } else setIsAtBottom(false);
    };
    React.useEffect(() => {
        const container = listRef.current;
        if (!container) return;
        if (shouldAutoScrollToBottom && !shouldDisableAutoScrollingRef.current) {
            setIsAtBottom(true);
            scrollToBottom(listRef, scrollBehavior);
            return;
        }
        if (!shouldAutoScrollToBottom) {
            const isAtBottom = container.scrollTop + container.clientHeight >= container.scrollHeight;
            setIsAtBottom(isAtBottom);
        }
    }, [listContent]);
    return { isAtBottom, setIsAtBottom, onScroll };
}

export function scrollToBottom(listRef: React.MutableRefObject<HTMLDivElement | null>, scrollBehavior: ScrollBehavior) {
    listRef.current?.scrollTo({ top: listRef.current.scrollHeight, behavior: scrollBehavior });
}

export function useSuggestedProductsDebugLogs({
    messages,
}: {
    messages: ProductDiscoveryConversationMessageWithProducts[] | undefined;
}) {
    const isSandboxEnvironment = !!useHasFeature(Feature.useProductDiscoverySandboxEnvironment);
    React.useEffect(() => {
        if (!isSandboxEnvironment || !messages?.length) return;
        const newSuggestedMerchantProductOffers = messages[messages.length - 1].merchantProductOffers;
        if (!newSuggestedMerchantProductOffers) return;
        console.log('Suggested MPOs:', JSON.stringify(newSuggestedMerchantProductOffers, null, 2));
    }, [messages, isSandboxEnvironment]);
}
