// This file is temporary and will be removed once we are convinced about the new design

import React from 'react';
import styled from 'styled-components';

import { colors } from '../../../tokens/theme';
import { CLICKABLE_ELEMENT_CSS_MIXIN } from '../../../style/styleConstants';
import { font } from '../../../style/text';

export function SwitchDesignVersionButton({
    isLegacyDesign,
    conversationId,
}: {
    isLegacyDesign: boolean;
    conversationId: string;
}) {
    return (
        <SwitchDesignVersionButtonContainer>
            <SwitchDesignVersionButtonStyled
                onClick={() => {
                    if (isLegacyDesign) window.history.pushState({}, '', `/product-discovery/${conversationId}`);
                    else window.history.pushState({}, '', `/product-discovery-legacy/${conversationId}`);
                    window.location.reload();
                }}>
                {'Switch design'}
            </SwitchDesignVersionButtonStyled>
        </SwitchDesignVersionButtonContainer>
    );
}

const SwitchDesignVersionButtonContainer = styled.div`
    position: fixed;
    top: 48px;
    right: 12px;
    border: 1px solid ${colors.border.primary};
    border-radius: 4px;
    padding: 4px;
    background-color: white;

    ${CLICKABLE_ELEMENT_CSS_MIXIN}

    &:hover {
        background-color: ${colors.background.subtle};
    }
`;

const SwitchDesignVersionButtonStyled = styled.div`
    cursor: pointer;
    font-family: ${font.ambitRegular};
    font-size: 14px;
`;
