import React from 'react';
import styled from 'styled-components';

import { getLocalizedTexts } from '../../../Locales';
import { useApolloClient } from '../../../api/config';
import { ApolloClient } from '../../../api/graphql/client';
import { SettingKey } from '../../../api/graphql/fragments/settings';
import { useUserInfo } from '../../../lib/common/userInfo';
import { getSettingFromLocalStorage } from '../../../lib/settings/settings';
import { CLICKABLE_ELEMENT_CSS_MIXIN, NO_SCROLLBAR_CSS_MIXIN } from '../../../style/styleConstants';
import { deviceWidthMeasurements } from '../../../style/size';
import { font } from '../../../style/text';
import { useViewportSize } from '../../../style/utils';
import { colors } from '../../../tokens/theme';
import { productDiscoveryContentWidths } from './constants';

const logo = '/assets/images/logos/lightning-round.svg';

export function LandingPageContent({
    sendMessage,
    isInputBarFocused,
}: {
    sendMessage: (suggestedMessage?: string) => void;
    isInputBarFocused: boolean;
}) {
    const [userInfo] = useUserInfo();
    const texts = getLocalizedTexts().productDiscovery.landingPage;
    const displayedSuggestions = useDisplayedSuggestions();
    return (
        <LandingPageContainer>
            <LandingPageInnerContainer {...{ isInputBarFocused }}>
                <JokoLogoLandingPageImage src={logo} />
                {userInfo.firstName ? (
                    <LandingPageTitleText>
                        {texts.titleWithFirstName({ firstName: userInfo.firstName })}
                    </LandingPageTitleText>
                ) : (
                    <LandingPageTitleText> {texts.titleWithoutFirstName}</LandingPageTitleText>
                )}
                <LandingPageSubtitleText>{texts.subtitle}</LandingPageSubtitleText>
                <UserMessageSuggestionsContainer>
                    {displayedSuggestions.map((suggestedMessage) => (
                        <UserMessageSuggestionsText key={suggestedMessage} onClick={() => sendMessage(suggestedMessage)}>
                            {suggestedMessage}
                        </UserMessageSuggestionsText>
                    ))}
                </UserMessageSuggestionsContainer>
            </LandingPageInnerContainer>
        </LandingPageContainer>
    );
}

function useDisplayedSuggestions() {
    const apolloClient = useApolloClient();
    const { width: viewportWidth } = useViewportSize();
    const [displayedSuggestions, setDisplayedSuggestions] = React.useState<string[]>([]);
    React.useEffect(() => {
        async function fetchSuggestions() {
            if (!apolloClient) return;
            const suggestions = await getDisplayedSuggestions(apolloClient, viewportWidth);
            setDisplayedSuggestions(suggestions);
        }
        fetchSuggestions();
    }, [apolloClient, viewportWidth]);
    return displayedSuggestions;
}

async function getDisplayedSuggestions(apolloClient: ApolloClient, viewportWidth: number) {
    const texts = getLocalizedTexts().productDiscovery;
    const userMessageSuggestionsSetting = await getSettingFromLocalStorage(
        apolloClient,
        SettingKey.productDiscoveryUserMessageSuggestions
    );
    const userMessageSuggestions = userMessageSuggestionsSetting ? JSON.parse(userMessageSuggestionsSetting) : {};
    const userMessageSuggestionsMobile = userMessageSuggestions.mobile || texts.landingPage.userMessageSuggestionsMobile;
    const userMessageSuggestionsDesktop = userMessageSuggestions.desktop || texts.landingPage.userMessageSuggestionsDesktop;
    return window.innerWidth < 380
        ? userMessageSuggestionsMobile.slice(0, userMessageSuggestionsMobile.length - 1)
        : window.innerWidth < 490
        ? userMessageSuggestionsMobile
        : viewportWidth < 550
        ? userMessageSuggestionsMobile.slice(0, 3)
        : viewportWidth < 790
        ? userMessageSuggestionsDesktop.slice(0, 5)
        : userMessageSuggestionsDesktop;
}

const LandingPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto;

    @media (min-width: ${deviceWidthMeasurements.small}) & (max-height: 600px) {
        padding-top: 24px;
    }
    @media (max-width: ${deviceWidthMeasurements.small}) {
        width: calc(100% - 32px);
        padding-top: 0px;
        margin: 0px 16px;
    }

    ${NO_SCROLLBAR_CSS_MIXIN}
`;

const LandingPageInnerContainer = styled.div<{ isInputBarFocused: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: ${productDiscoveryContentWidths.xxLarge};

    @media (max-width: ${deviceWidthMeasurements.xLarge}) {
        max-width: ${productDiscoveryContentWidths.large};
    }
    @media (max-width: ${deviceWidthMeasurements.medium}) {
        max-width: ${productDiscoveryContentWidths.medium};
    }
    @media (max-width: ${deviceWidthMeasurements.small}) {
        display: ${({ isInputBarFocused }) => (isInputBarFocused ? 'none' : 'flex')};
    }
`;

const UserMessageSuggestionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: stretch;
    gap: 16px 16px;
`;

const JokoLogoLandingPageImage = styled.img`
    width: 54px;
    height: 54px;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        margin-top: 48px;
    }
`;

const LandingPageTitleText = styled.div`
    margin-top: 32px;
    font-size: 32px;
    font-family: ${font.ambitBold};

    @media (max-width: ${deviceWidthMeasurements.small}) {
        margin-top: 28px;
        font-size: 22px;
    }
`;

const LandingPageSubtitleText = styled.div`
    margin-top: 24px;
    margin-bottom: 32px;
    font-size: 18px;
    text-align: center;
    font-family: ${font.ambitSemiBold};
    color: ${colors.content.secondary};

    @media (max-width: ${deviceWidthMeasurements.small}) {
        max-width: 360px;
        font-size: 16px;
        margin-top: 8px;
        margin-bottom: 24px;
    }
`;

const UserMessageSuggestionsText = styled.div`
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 32px;
    padding-right: 32px;
    font-size: 16px;
    font-family: ${font.ambitSemiBold};
    color: ${colors.content.secondary};
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.16);
    ${CLICKABLE_ELEMENT_CSS_MIXIN}

    &:hover {
        background-color: ${colors.background.subtle};
    }
`;
