import React from 'react';
import styled from 'styled-components';

import { getLocalizedTexts } from '../../../Locales';
import { font } from '../../../style/text';
import { colors } from '../../../tokens/colors/colors';

export function ErrorMessageOnFetchConversation() {
    const texts = getLocalizedTexts().productDiscovery;
    return (
        <ErrorMessageContainer>
            <ErrorMessageText>{texts.errorMessageOnFetchConversation}</ErrorMessageText>
        </ErrorMessageContainer>
    );
}

const ErrorMessageContainer = styled.div`
    display: flex;
    width: 50%;
    align-self: center;
    margin-top: 32px;
`;

const ErrorMessageText = styled.div`
    font-size: 16px;
    font-family: ${font.ambitSemiBold};
    text-align: center;
    color: ${colors.content.secondary};
`;
