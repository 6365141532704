import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../tokens/theme';
import { font } from '../../../style/text';
import { getLocalizedTexts } from '../../../Locales';
import { isMobileDevice } from '../../../style/utils';

const historyIcon = '/assets/images/icons/history-light.svg';

export function ConversationHistorySectionEmptyState() {
    const texts = getLocalizedTexts().productDiscovery.conversationHistory.emptyState;
    return (
        <ConversationHistorySectionEmptyStateContainer {...{ isMobileDevice }}>
            <HistoryIconContainer {...{ isMobileDevice }}>
                <HistoryIcon src={historyIcon} {...{ isMobileDevice }} />
            </HistoryIconContainer>
            <ConversationHistorySectionEmptyStateTitle>{texts.title}</ConversationHistorySectionEmptyStateTitle>
            <ConversationHistorySectionEmptyStateSubTitle>{texts.content}</ConversationHistorySectionEmptyStateSubTitle>
        </ConversationHistorySectionEmptyStateContainer>
    );
}

const ConversationHistorySectionEmptyStateContainer = styled.div<{ isMobileDevice: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${({ isMobileDevice }) => (isMobileDevice ? '254px' : '360px')};
    height: 100%;
`;

const HistoryIconContainer = styled.div<{ isMobileDevice: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ isMobileDevice }) => (isMobileDevice ? '32px' : '48px')};
    height: ${({ isMobileDevice }) => (isMobileDevice ? '32px' : '48px')};
    margin-bottom: 8px;
    border-radius: 96px;
    background-color: ${colors.background.subtle};
`;

const ConversationHistorySectionEmptyStateTitle = styled.div`
    margin-bottom: 4px;
    font-family: ${font.ambitBold};
    font-size: 16px;
    text-align: center;
    color: ${colors.content.default};
`;

const ConversationHistorySectionEmptyStateSubTitle = styled.div`
    font-family: ${font.ambitSemiBold};
    font-size: 14px;
    text-align: center;
    color: ${colors.content.defaultDisabled};
`;

const HistoryIcon = styled.img<{ isMobileDevice: boolean }>`
    width: ${({ isMobileDevice }) => (isMobileDevice ? '16px' : '30px')};
    height: ${({ isMobileDevice }) => (isMobileDevice ? '16px' : '30px')};
`;
