import React from 'react';
import styled from 'styled-components';
import { getLocalizedTexts } from '../../../Locales';
import { colors } from '../../../tokens/colors/colors';
import { font } from '../../../style/text';
import { isMobileDevice } from '../../../style/utils';
import {
    ConversationHistoryItemsByDate,
    useDeleteConversationHistory,
} from '../../../lib/productDiscovery/conversationHistory';
import { ProductDiscoveryConversationMessageWithProducts } from '../../../lib/productDiscovery/conversationMessages';

export function DeleteConversationHistoryConfirmationModal({
    setShouldShowDeleteHistoryConfirmation,
    setConversationHistoryItemsByDate,
    resetConversation,
}: {
    setShouldShowDeleteHistoryConfirmation: (value: boolean) => void;
    setConversationHistoryItemsByDate: (value: ConversationHistoryItemsByDate) => void;
    resetConversation: ({
        conversationToReopen,
    }: {
        conversationToReopen?: {
            conversationId: string;
            messages: ProductDiscoveryConversationMessageWithProducts[];
        };
    }) => void;
}) {
    const deleteConversationHistory = useDeleteConversationHistory(setConversationHistoryItemsByDate);
    const handleClickButtonDeleteHistory = () => {
        resetConversation({});
        deleteConversationHistory();
        setShouldShowDeleteHistoryConfirmation(false);
    };
    const texts = getLocalizedTexts().productDiscovery.conversationHistory;
    return (
        <DeleteHistoryConfirmationModalContainer {...{ isMobileDevice }}>
            <TitleDeleteHistoryModal>{texts.deleteHistory.title}</TitleDeleteHistoryModal>
            <DeleteConversationHistoryButton {...{ isMobileDevice }} onClick={handleClickButtonDeleteHistory}>
                {texts.deleteHistory.deleteButton}
            </DeleteConversationHistoryButton>
            <CancelButton {...{ isMobileDevice }} onClick={() => setShouldShowDeleteHistoryConfirmation(false)}>
                {texts.deleteHistory.cancelButton}
            </CancelButton>
        </DeleteHistoryConfirmationModalContainer>
    );
}

const DeleteHistoryConfirmationModalContainer = styled.div<{ isMobileDevice: boolean }>`
    position: fixed;
    z-index: 3;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${({ isMobileDevice }) => (isMobileDevice ? 'calc(100% - 64px)' : '552px')};
    padding: 16px;
    border-radius: 20px;
    box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
    background-color: ${colors.background.default};
    transform: translate(-50%, -50%);
`;

const TitleDeleteHistoryModal = styled.div`
    margin-bottom: 24px;
    font-family: ${font.ambitBold};
    font-size: 22px;
    color: ${colors.content.default};
`;

const DeleteConversationHistoryButton = styled.div<{ isMobileDevice: boolean }>`
    display: flex;
    justify-content: center;
    width: ${({ isMobileDevice }) => (isMobileDevice ? 'calc(100% - 32px)' : '488px')};
    padding: 12px 16px;
    border-radius: 96px;
    background-color: ${colors.background.primary};
    font-family: ${font.ambitBold};
    font-size: 16px;
    color: ${colors.content.primary};
    margin-bottom: 12px;

    &:hover {
        cursor: pointer;
    }
`;

const CancelButton = styled.div<{ isMobileDevice: boolean }>`
    display: flex;
    justify-content: center;
    width: ${({ isMobileDevice }) => (isMobileDevice ? 'calc(100% - 32px)' : '488px')};
    padding: 12px 16px;
    border: 2px solid ${colors.border.default};
    border-radius: 96px;
    background-color: ${colors.background.default};
    font-family: ${font.ambitBold};
    font-size: 16px;
    color: ${colors.content.default};

    &:hover {
        cursor: pointer;
    }
`;
