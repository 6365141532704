import React from 'react';
import styled from 'styled-components';

import { UserProductDiscoveryConversationHistoryItem } from '../../../api/graphql/fragments/productDiscovery';
import { colors } from '../../../tokens/colors/colors';
import { CLICKABLE_ELEMENT_CSS_MIXIN } from '../../../style/styleConstants';
import { font } from '../../../style/text';
import { getLocalizedTexts } from '../../../Locales';
import { capitalizeFirstLetter, isMobileDevice } from '../../../style/utils';
import { ProductDiscoveryConversationMessageWithProducts } from '../../../lib/productDiscovery/conversationMessages';
import { useDeleteConversation } from '../../../lib/productDiscovery/conversationHistoryItem';
import { ConversationHistoryItemsByDate } from '../../../lib/productDiscovery/conversationHistory';
import { DeleteConversationConfirmationModal } from './DeleteConversationConfirmationModal';

const deleteConversationIcon = '/assets/images/icons/cross-grey-slim.svg';

const LONG_PRESS_THRESHOLD = 500;

export function ConversationHistoryItem({
    currentConversationId,
    conversation,
    selectedConversationHistoryItemId,
    setSelectedConversationHistoryItemId,
    lastConversationTitle,
    setMessages,
    setFailedToFetchConversation,
    conversationToDeleteOnMobile,
    setConversationToDeleteOnMobile,
    conversationHistoryItemsByDate,
    setConversationHistoryItemsByDate,
    resetConversation,
}: {
    currentConversationId: string;
    conversation: UserProductDiscoveryConversationHistoryItem;
    selectedConversationHistoryItemId: string | undefined;
    setSelectedConversationHistoryItemId: (value: string | undefined) => void;
    lastConversationTitle: string | undefined;
    setMessages: (messages: ProductDiscoveryConversationMessageWithProducts[]) => void;
    setFailedToFetchConversation: (value: boolean) => void;
    conversationToDeleteOnMobile: string | undefined;
    setConversationToDeleteOnMobile: (value: string | undefined) => void;
    conversationHistoryItemsByDate: ConversationHistoryItemsByDate;
    setConversationHistoryItemsByDate: (value: ConversationHistoryItemsByDate) => void;
    resetConversation: ({
        conversationToReopen,
    }: {
        conversationToReopen?: {
            conversationId: string;
            messages: ProductDiscoveryConversationMessageWithProducts[];
        };
    }) => void;
}) {
    const parentRef = React.useRef<HTMLDivElement>(null);
    const tooltipRef = React.useRef<HTMLDivElement>(null);
    const [pressTimer, setPressTimer] = React.useState<NodeJS.Timeout | null>(null);
    const texts = getLocalizedTexts().productDiscovery.conversationHistory;
    const [tooltipTopPosition, setTooltipTopPosition] = React.useState<number | null>(null);
    const [isTooltipVisible, setIsTooltipVisible] = React.useState(false);
    const updateTooltipPosition = () => {
        if (parentRef.current) {
            const parentRect = parentRef.current.getBoundingClientRect();
            const tooltipTop = parentRect.bottom + 10;
            setTooltipTopPosition(tooltipTop);
        }
    };
    React.useEffect(() => {
        if (isTooltipVisible) updateTooltipPosition();
    }, [isTooltipVisible]);

    const onConversationHistoryItemClick = async (conversation: UserProductDiscoveryConversationHistoryItem) => {
        if (conversation.conversationId === selectedConversationHistoryItemId) return;
        setMessages([]);
        setFailedToFetchConversation(false);
        setSelectedConversationHistoryItemId(conversation.conversationId);
    };
    const handlePressStart = () => {
        const timer = setTimeout(() => {
            setConversationToDeleteOnMobile(conversation.conversationId);
        }, LONG_PRESS_THRESHOLD);
        setPressTimer(timer);
    };
    const handlePressEnd = () => {
        if (pressTimer !== null) clearTimeout(pressTimer);
        setPressTimer(null);
    };
    const isSelected =
        conversation.conversationId === selectedConversationHistoryItemId ||
        conversation.conversationId === currentConversationId;
    const { isConversationBeingDeleted, setIsConversationBeingDeleted, cancelConversationDelete } = useDeleteConversation(
        conversation.conversationId,
        conversationHistoryItemsByDate,
        setConversationHistoryItemsByDate
    );
    const handleClickDeleteConversation = (event?: React.MouseEvent) => {
        event?.stopPropagation();
        setIsConversationBeingDeleted(true);
        setIsTooltipVisible(false);
        if (isSelected) resetConversation({});
    };
    return (
        <>
            {conversationToDeleteOnMobile === conversation.conversationId ? (
                <DeleteConversationConfirmationModal
                    {...{
                        handleClickDeleteConversation,
                        setConversationToDeleteOnMobile,
                        conversationId: conversation.conversationId,
                    }}
                />
            ) : null}
            {isConversationBeingDeleted ? (
                <ConversationBeingDeletedContainer {...{ isMobileDevice }}>
                    {!isMobileDevice ? (
                        <ConversationBeingDeletedText>
                            {texts.deleteConversation.deletionConfirmationMessage}
                        </ConversationBeingDeletedText>
                    ) : null}
                    <CancelConversationDeletionButton onClick={cancelConversationDelete}>
                        {texts.deleteConversation.undoDeletion}
                    </CancelConversationDeletionButton>
                </ConversationBeingDeletedContainer>
            ) : (
                <ConversationsHistoryTextContainer
                    {...{ isSelected }}
                    key={conversation.conversationId}
                    onClick={() => onConversationHistoryItemClick(conversation)}
                    onTouchStart={handlePressStart}
                    onTouchEnd={handlePressEnd}
                    onTouchMove={handlePressEnd}>
                    <ConversationsHistoryText>
                        {conversation.conversationId === currentConversationId && lastConversationTitle
                            ? lastConversationTitle
                            : capitalizeFirstLetter(conversation.conversationTitle)}
                    </ConversationsHistoryText>
                    {!isMobileDevice ? (
                        <DeleteConversationIconContainer
                            onClick={handleClickDeleteConversation}
                            ref={parentRef}
                            onMouseEnter={() => setIsTooltipVisible(true)}
                            onMouseLeave={() => setIsTooltipVisible(false)}>
                            <DeleteConversationIcon src={deleteConversationIcon} />
                            {isTooltipVisible && tooltipTopPosition !== null && (
                                <DeleteConversationTooltip ref={tooltipRef} tooltipTopPosition={tooltipTopPosition}>
                                    {texts.deleteConversation.toolTip}
                                </DeleteConversationTooltip>
                            )}
                        </DeleteConversationIconContainer>
                    ) : null}
                </ConversationsHistoryTextContainer>
            )}
        </>
    );
}

const ConversationsHistoryTextContainer = styled.div<{
    isSelected: boolean;
}>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px;
    background-color: ${({ isSelected }) => (isSelected ? colors.background.light : '')};

    &:hover {
        background-color: ${colors.background.light};
        cursor: pointer;
        img {
            opacity: 1;
        }
    }

    img {
        opacity: 0;
    }
`;

const ConversationBeingDeletedContainer = styled.div<{ isMobileDevice: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: ${({ isMobileDevice }) => (isMobileDevice ? 'center' : 'space-between')};
    align-items: center;
    padding: 8px 24px;
    background-color: ${colors.background.light};

    &:hover {
        cursor: pointer;
    }
`;

const ConversationsHistoryText = styled.div`
    display: -webkit-box;
    overflow: hidden;
    font-family: ${font.ambitSemiBold};
    font-size: 16px;
    color: ${colors.content.default};
    white-space: nowrap;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`;

const ConversationBeingDeletedText = styled.div`
    font-family: ${font.ambitSemiBold};
    font-size: 16px;
    background-color: ${colors.background.light};
    text-align: center;
    color: ${colors.content.defaultDisabled};
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`;

const CancelConversationDeletionButton = styled.div`
    font-family: ${font.ambitSemiBold};
    font-size: 16px;
    color: ${colors.content.defaultDisabled};
    text-decoration: underline;
`;

const DeleteConversationIconContainer = styled.div`
    position: relative;
    display: inline-block;
    align-items: center;
    height: 16px;
`;

const DeleteConversationTooltip = styled.div<{ tooltipTopPosition: number }>`
    position: fixed;
    top: ${({ tooltipTopPosition }) => tooltipTopPosition}px;
    left: 180px;
    padding: 4px 6px;
    border-radius: 4px;
    background-color: ${colors.background.primaryDisabled};
    font-family: ${font.ambitSemiBold};
    font-size: 16px;
    color: ${colors.content.primary};
    white-space: nowrap;
    transform: translateX(50%);
    pointer-events: none; /* Prevent tooltip from interfering with hover */
`;

const DeleteConversationIcon = styled.img`
    width: 16px;
    height: 16px;
    ${CLICKABLE_ELEMENT_CSS_MIXIN}
`;
