import React from 'react';

import {
    deleteConversationMutation,
    DeleteConversationMutationVariables,
    DeleteConversationResponse,
} from '../../api/graphql/mutations/deleteConversation';
import { ConversationHistoryItemsByDate } from './conversationHistory';
import { ApolloClient } from '../../api/graphql/client';
import {
    userProductDiscoveryConversationsQuery,
    UserProductDiscoveryConversationsQueryResponse,
} from '../../api/graphql/queries/productDiscoveryConversations';
import { useApolloClient } from '@apollo/react-hooks';

const DELETE_CONVERSATION_POSTPONE_DURATION_IN_MS = 5000;

export function useDeleteConversation(
    conversationId: string,
    conversationHistoryItemsByDate: ConversationHistoryItemsByDate,
    setConversationHistoryItemsByDate: (value: ConversationHistoryItemsByDate) => void
) {
    const deleteConversation = useDeleteConversationMutation(
        conversationId,
        conversationHistoryItemsByDate,
        setConversationHistoryItemsByDate
    );
    const deleteConversationTimeoutHandleRef = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
    const [isConversationBeingDeleted, setIsConversationBeingDeleted] = React.useState(false);
    const cancelConversationDelete = () => {
        if (deleteConversationTimeoutHandleRef.current) {
            clearTimeout(deleteConversationTimeoutHandleRef.current);
            deleteConversationTimeoutHandleRef.current = undefined;
        }
        setIsConversationBeingDeleted(false);
    };
    React.useEffect(() => {
        if (isConversationBeingDeleted)
            deleteConversationTimeoutHandleRef.current = setTimeout(() => {
                deleteConversationTimeoutHandleRef.current = undefined;
                deleteConversation();
                setIsConversationBeingDeleted(false);
            }, DELETE_CONVERSATION_POSTPONE_DURATION_IN_MS);
        // If the tab is closed while the product is being unsaved, we also validate the deletion.
        return () => {
            if (!deleteConversationTimeoutHandleRef.current) return;
            if (isConversationBeingDeleted) deleteConversation();
            clearTimeout(deleteConversationTimeoutHandleRef.current);
            deleteConversationTimeoutHandleRef.current = undefined;
        };
    }, [isConversationBeingDeleted]);
    return {
        isConversationBeingDeleted,
        setIsConversationBeingDeleted,
        cancelConversationDelete,
    };
}

function useDeleteConversationMutation(
    conversationId: string,
    conversationHistoryItemsByDate: ConversationHistoryItemsByDate,
    setConversationHistoryItemsByDate: (value: ConversationHistoryItemsByDate) => void
) {
    const apolloClient = useApolloClient();
    const deleteConversation = async () => {
        try {
            if (!apolloClient) return;
            const updatedConversationHistory = [...conversationHistoryItemsByDate];
            let index = 0;
            for (const dateGroup of updatedConversationHistory) {
                if (conversationId in dateGroup.conversations) {
                    dateGroup.conversations = dateGroup.conversations.filter(
                        (conversation) => conversation.conversationId !== conversationId
                    );
                    updatedConversationHistory[index] = dateGroup;
                    // If there are no more conversations in the date group, we remove the group from the history
                    if (dateGroup.conversations.length === 0)
                        updatedConversationHistory.splice(updatedConversationHistory.indexOf(dateGroup), 1);
                    break;
                }
                index++;
            }
            setConversationHistoryItemsByDate(updatedConversationHistory);
            deleteConversationHistoryItemInCache(apolloClient, conversationId, false);
            await apolloClient.mutate<DeleteConversationResponse, DeleteConversationMutationVariables>({
                mutation: deleteConversationMutation,
                fetchPolicy: 'no-cache',
                variables: { conversationId },
            });
        } catch (error) {
            console.error(error);
        }
    };
    return deleteConversation;
}

function deleteConversationHistoryItemInCache(
    apolloClient: ApolloClient,
    conversationId: string,
    isSandboxEnvironment: boolean
) {
    let valueInCache = undefined;
    try {
        valueInCache = apolloClient.readQuery<UserProductDiscoveryConversationsQueryResponse>({
            query: userProductDiscoveryConversationsQuery,
            variables: {},
        });
    } catch (error) {
        if (isSandboxEnvironment) console.error('Error while fetching messages with products in cache', error);
    }
    let updatedProductDiscoveryConversations = valueInCache?.user?.productDiscoveryConversations?.items || [];
    updatedProductDiscoveryConversations = updatedProductDiscoveryConversations.filter(
        (conversation) => conversation.conversationId !== conversationId
    );
    apolloClient.writeQuery<UserProductDiscoveryConversationsQueryResponse>({
        query: userProductDiscoveryConversationsQuery,
        variables: {},
        data: {
            __typename: 'Query',
            user: {
                __typename: 'User',
                productDiscoveryConversations: {
                    __typename: 'UserProductDiscoveryConversationList',
                    items: updatedProductDiscoveryConversations,
                    nextToken: valueInCache?.user?.productDiscoveryConversations?.nextToken || null,
                },
            },
        },
    });
}
