import { Size } from '../../../tokens/measurements/size';

type ProductDiscoveryContentWidthScale = '474px' | '630px' | '710px';

type ProductDiscoveryContentWidth = Partial<Record<Size, ProductDiscoveryContentWidthScale>>;

export const productDiscoveryContentWidths: ProductDiscoveryContentWidth = {
    medium: '474px',
    large: '630px',
    xxLarge: '710px',
};
