import React from 'react';
import { MerchantProductOffer } from './conversationMessages';

/**
 * This hook handles loading and error states for product images, to make sure that we only display images
 * that are not a placeholder (tested with simple heuristics) and which load successfully.
 * For a given product, candidate image URLs are tried one after the other in the following order: the main URL `mainImageUrls` first, followed by the `alternateImagesUrls`.
 */
export function useProductImage(offer: MerchantProductOffer) {
    const [isImageLoading, setIsImageLoading] = React.useState(true);
    const [imageUrl, setImageUrl] = React.useState(getImageUrl(offer));
    function onImageLoad() {
        setIsImageLoading(false);
    }
    function onImageError(currentImageUrl: string) {
        const backupImageUrl = getImageUrl(offer, currentImageUrl);
        setImageUrl(backupImageUrl);
    }
    return { isImageLoading, imageUrl, onImageLoad, onImageError };
}

function getImageUrl(offer: MerchantProductOffer, failedImageUrl?: string) {
    const orderedCandidateUrls = [
        offer.mainImageUrls.defaultSize,
        ...(offer.alternateImagesUrls || []).map((x) => x.defaultSize),
    ];
    const failedImageUrlIndex = failedImageUrl ? orderedCandidateUrls.indexOf(failedImageUrl) : -1;
    const remainingCandidateUrls = orderedCandidateUrls.slice(failedImageUrlIndex + 1); // As candidate images are tried one after the other, we skip the ones that previously failed
    for (const candidateUrl of remainingCandidateUrls) {
        if (candidateUrl && !checkIsPlaceholderImage(candidateUrl)) return candidateUrl;
    }
    return undefined;
}

function checkIsPlaceholderImage(imageUrl: string) {
    return imageUrl.includes('noimage.gif');
}
