import React from 'react';
import { useHistory, useParams } from 'react-router';
import { ActivityIndicator, View } from 'react-native';
import styled from 'styled-components';

import { formatCurrencyAmount, getLocalizedTexts } from '../../Locales';
import { useApolloClient } from '../../api/config';
import { ApolloClient } from '../../api/graphql/client';
import { AnimatedGradientBackground } from '../../components/common/GradientBackground';
import GradientText from '../../components/common/GradientText';
import { getUserId } from '../../lib/common/cognito';
import { useUserInfo } from '../../lib/common/userInfo';
import { Feature, useHasFeature } from '../../lib/features/buildFeatureMap';
import {
    scrollToBottom,
    MerchantIdToMerchantNameMap,
    MerchantProductOffer,
    ProductDiscoveryConversationMessageWithProducts,
    useHandleConnectionWithAssistant,
    useMerchantIdToMerchantNameMap,
    useResetConversation,
    useHandleScroll,
    useSendMessage,
    useSuggestedProductsDebugLogs,
} from '../../lib/productDiscovery/conversationMessages';
import { logUserEventUtil } from '../../lib/events/userEvents';
import { useShouldShowConversationHistory } from '../../lib/productDiscovery/conversationHistory';
import { CLICKABLE_ELEMENT_CSS_MIXIN, NO_SCROLLBAR_CSS_MIXIN } from '../../style/styleConstants';
import Thumb from '../../style/reactSvg/Thumb';
import color from '../../style/color';
import { deviceWidthMeasurements } from '../../style/size';
import { font } from '../../style/text';
import { isMobileDevice, useViewportSize } from '../../style/utils';
import { colors } from '../../tokens/colors/colors';
import { Size } from '../../tokens/measurements/size';
import { ConversationHistorySection, ErrorMessageOnFetchConversation } from './ConversationHistorySectionLegacy';
import { Header } from './HeaderLegacy';
import { SettingKey } from '../../api/graphql/fragments/settings';
import { getSettingFromLocalStorage } from '../../lib/settings/settings';
import {
    createUserAndAssistantMessages,
    decodeRedirectionUrlParams,
} from '../../lib/productDiscovery/redirectionFromExtension';
import { UserProductDiscoveryConversationHistoryItem } from '../../api/graphql/fragments/productDiscovery';

const logo = '/assets/images/logos/lightning-round.svg';
const arrowHeadUp = '/assets/images/icons/arrow-head-up-white.svg';
const productLinkArrow = '/assets/images/icons/product-link-arrow.svg';
const chevronGrayDown = '/assets/images/icons/chevron-gray-down-new.svg';

function ProductDiscoveryPageLegacy() {
    const history = useHistory();
    React.useEffect(() => {
        document.title = 'Joko AI';
    }, []);
    const isProductDiscoveryEnabled = useHasFeature(Feature.productDiscoveryInWebApp);
    if (isProductDiscoveryEnabled) return <ProductDiscoveryContent />;
    if (isProductDiscoveryEnabled === undefined) return <LoadingPage />;
    history.push('/home');
    return null;
}

export default ProductDiscoveryPageLegacy;

function ProductDiscoveryContent() {
    const conversationParam = useParams<{ conversationId?: string }>();
    const conversationIdParam = conversationParam.conversationId === 'new' ? undefined : conversationParam.conversationId;
    const { urlParams } = useParams<{ urlParams?: string }>();
    const { userSearchQuery, assistantMessageContent, partialMerchantProductOffers } = decodeRedirectionUrlParams(
        urlParams || ''
    );
    const [messages, setMessages] = React.useState<ProductDiscoveryConversationMessageWithProducts[] | undefined>(
        conversationIdParam
            ? []
            : userSearchQuery && assistantMessageContent
            ? createUserAndAssistantMessages(userSearchQuery, assistantMessageContent)
            : undefined
    );
    const [firstMessageToSend, setFirstMessageToSend] = React.useState<string | undefined>(undefined);
    const [inputText, setInputText] = React.useState<string>('');
    const inputTextRef = React.useRef<HTMLTextAreaElement>(null);
    const [conversationId, setConversationId] = React.useState<string>('');
    const webSocketRef = React.useRef<WebSocket | undefined>(undefined);
    const userId = React.useMemo(() => getUserId(), []);
    const apolloClient = useApolloClient();
    let messageCounterRef = React.useRef(0);
    const [userFeedbackMap, setUserFeedbackMap] = React.useState<{ [id: string]: 'positive' | 'negative' | null }>({});
    const [isAssistantThinking, setIsAssistantThinking] = React.useState(false);
    const messagesListRef = React.useRef<HTMLDivElement | null>(null);
    const { shouldShowConversationHistory, setShouldShowConversationHistory } = useShouldShowConversationHistory();
    const [failedToFetchConversation, setFailedToFetchConversation] = React.useState(false);
    const { isAtBottom, setIsAtBottom, onScroll } = useHandleScroll({
        listRef: messagesListRef,
        listContent: messages,
    });
    const [isInputBarFocused, setIsInputBarFocused] = React.useState(false);
    const [isWebSocketConnectionOpen, setIsWebSocketConnectionOpen] = React.useState(false);
    const [selectedConversationHistoryItemId, setSelectedConversationHistoryItemId] = React.useState<string | undefined>(
        conversationIdParam
    );
    const [bottomPositionScrollButton, setBottomPositionScrollButton] = React.useState(0);
    const [lastConversationHistoryItem, setLastConversationHistoryItem] = React.useState<
        UserProductDiscoveryConversationHistoryItem | undefined
    >(undefined);
    const [lastConversationTitle, setLastConversationTitle] = React.useState<string | undefined>(undefined);
    const { initializeWebSocket, closeWebSocket } = useHandleConnectionWithAssistant({
        userId,
        webSocketRef,
        setIsWebSocketConnectionOpen,
        messageCounterRef,
        messages,
        setMessages,
        setIsAssistantThinking,
        conversationId,
        setConversationId,
        partialMerchantProductOffers,
        setLastConversationHistoryItem,
        setLastConversationTitle,
    });
    const sendMessage = useSendMessage({
        userId,
        webSocketRef,
        isWebSocketConnectionOpen,
        messageCounterRef,
        messages,
        setMessages,
        conversationId,
        inputText,
        setInputText,
        initializeWebSocket,
        messagesListRef,
        setIsAssistantThinking,
        firstMessageToSend,
        setFirstMessageToSend,
    });
    const resetConversation = useResetConversation({
        conversationId,
        setMessages,
        messageCounterRef,
        messagesListRef,
        setIsAssistantThinking,
        setFirstMessageToSend,
        setConversationId,
        initializeWebSocket,
        closeWebSocket,
        setIsAtBottom,
        setUserFeedbackMap,
        setSelectedConversationHistoryItemId,
        setFailedToFetchConversation,
        setInputText,
        setLastConversationHistoryItem,
        setLastConversationTitle,
    });
    const merchantIdToMerchantNameMap = useMerchantIdToMerchantNameMap({ apolloClient, messages });
    useSuggestedProductsDebugLogs({ messages });
    usePreventWebpageScroll(shouldShowConversationHistory);
    const { height: viewportHeight } = useViewportSize();
    const renderMessage = getRenderMessage({
        apolloClient,
        merchantIdToMerchantNameMap,
        conversationId,
        userFeedbackMap,
        setUserFeedbackMap,
    });
    return (
        <>
            <Overlay
                {...{ isMobileDevice, shouldShowConversationHistory }}
                onClick={() => setShouldShowConversationHistory(false)}
            />
            <MainContainer {...{ viewportHeight, isMobileDevice }}>
                <ConversationHistorySection
                    {...{
                        conversationId,
                        resetConversation,
                        setMessages,
                        inputTextRef,
                        shouldShowConversationHistory,
                        setShouldShowConversationHistory,
                        selectedConversationHistoryItemId,
                        setSelectedConversationHistoryItemId,
                        setFailedToFetchConversation,
                        lastConversationHistoryItem,
                        lastConversationTitle,
                    }}
                />
                <ContentContainer>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                        <Header
                            {...{
                                resetConversation,
                                conversationId,
                                shouldShowConversationHistory,
                                setShouldShowConversationHistory,
                                inputTextRef,
                            }}
                        />
                        {!failedToFetchConversation ? (
                            <>
                                {messages === undefined ? (
                                    <LandingPageContent {...{ sendMessage, isInputBarFocused }} />
                                ) : (
                                    <MessagesContainer ref={messagesListRef} onScroll={onScroll}>
                                        <MessagesInnerContainer>
                                            {messages.map((message) => renderMessage({ item: message }))}
                                        </MessagesInnerContainer>
                                    </MessagesContainer>
                                )}
                                {!isAtBottom ? (
                                    <ScrollToBottomButton {...{ messagesListRef, bottomPositionScrollButton }} />
                                ) : null}
                                <InputBar
                                    {...{
                                        inputText,
                                        setInputText,
                                        inputTextRef,
                                        setIsInputBarFocused,
                                        sendMessage,
                                        isAssistantThinking,
                                        setBottomPositionScrollButton,
                                    }}
                                />
                            </>
                        ) : (
                            <ErrorMessageOnFetchConversation />
                        )}
                    </div>
                </ContentContainer>
            </MainContainer>
        </>
    );
}

function usePreventWebpageScroll(shouldShowConversationHistory: boolean) {
    React.useEffect(() => {
        if (isMobileDevice && shouldShowConversationHistory) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = '';
    }, [isMobileDevice, shouldShowConversationHistory]);
}

function getRenderMessage({
    apolloClient,
    merchantIdToMerchantNameMap,
    conversationId,
    userFeedbackMap,
    setUserFeedbackMap,
}: {
    apolloClient: ApolloClient | undefined;
    merchantIdToMerchantNameMap: MerchantIdToMerchantNameMap;
    conversationId: string;
    userFeedbackMap: { [id: string]: 'positive' | 'negative' | null };
    setUserFeedbackMap: React.Dispatch<React.SetStateAction<{ [id: string]: 'positive' | 'negative' | null }>>;
}): React.FC<{ item: ProductDiscoveryConversationMessageWithProducts }> {
    return ({ item: message }) => {
        if (message.role === 'user') return <UserMessage {...{ message }} key={message.messagePosition} />;
        return (
            <AssistantMessage
                {...{
                    message,
                    apolloClient,
                    merchantIdToMerchantNameMap,
                    conversationId,
                    userFeedbackMap,
                    setUserFeedbackMap,
                }}
                key={message.messagePosition}
            />
        );
    };
}

function LandingPageContent({
    sendMessage,
    isInputBarFocused,
}: {
    sendMessage: (suggestedMessage?: string) => void;
    isInputBarFocused: boolean;
}) {
    const [userInfo] = useUserInfo();
    const texts = getLocalizedTexts().productDiscovery.landingPage;
    const displayedSuggestions = useDisplayedSuggestions();
    return (
        <LandingPageContainer>
            <LandingPageInnerContainer {...{ isInputBarFocused }}>
                <JokoLogoLandingPageImage src={logo} />
                {userInfo.firstName ? (
                    <LandingPageTitleText>
                        {texts.titleWithFirstName({ firstName: userInfo.firstName })}
                    </LandingPageTitleText>
                ) : (
                    <LandingPageTitleText> {texts.titleWithoutFirstName}</LandingPageTitleText>
                )}
                <LandingPageSubtitleText>{texts.subtitle}</LandingPageSubtitleText>
                <UserMessageSuggestionsContainer>
                    {displayedSuggestions.map((suggestedMessage) => (
                        <UserMessageSuggestionsText key={suggestedMessage} onClick={() => sendMessage(suggestedMessage)}>
                            {suggestedMessage}
                        </UserMessageSuggestionsText>
                    ))}
                </UserMessageSuggestionsContainer>
            </LandingPageInnerContainer>
        </LandingPageContainer>
    );
}

function useDisplayedSuggestions() {
    const apolloClient = useApolloClient();
    const { width: viewportWidth } = useViewportSize();
    const [displayedSuggestions, setDisplayedSuggestions] = React.useState<string[]>([]);
    React.useEffect(() => {
        async function fetchSuggestions() {
            if (!apolloClient) return;
            const suggestions = await getDisplayedSuggestions(apolloClient, viewportWidth);
            setDisplayedSuggestions(suggestions);
        }
        fetchSuggestions();
    }, [apolloClient, viewportWidth]);
    return displayedSuggestions;
}

async function getDisplayedSuggestions(apolloClient: ApolloClient, viewportWidth: number) {
    const texts = getLocalizedTexts().productDiscovery;
    const userMessageSuggestionsSetting = await getSettingFromLocalStorage(
        apolloClient,
        SettingKey.productDiscoveryUserMessageSuggestions
    );
    const userMessageSuggestions = userMessageSuggestionsSetting ? JSON.parse(userMessageSuggestionsSetting) : {};
    const userMessageSuggestionsMobile = userMessageSuggestions.mobile || texts.landingPage.userMessageSuggestionsMobile;
    const userMessageSuggestionsDesktop = userMessageSuggestions.desktop || texts.landingPage.userMessageSuggestionsDesktop;
    return window.innerWidth < 380
        ? userMessageSuggestionsMobile.slice(0, userMessageSuggestionsMobile.length - 1)
        : window.innerWidth < 500
        ? userMessageSuggestionsMobile
        : viewportWidth < 740
        ? userMessageSuggestionsDesktop.slice(0, userMessageSuggestionsDesktop.length - 1)
        : userMessageSuggestionsDesktop;
}

function ScrollToBottomButton({
    messagesListRef,
    bottomPositionScrollButton,
}: {
    messagesListRef: React.RefObject<any>;
    bottomPositionScrollButton: number;
}) {
    return (
        <ScrollToBottomButtonContainer {...{ bottomPositionScrollButton }}>
            <ScrollToBottomButtonCircleContainer onClick={() => scrollToBottom(messagesListRef, 'smooth')}>
                <ChevronDownImage src={chevronGrayDown} />
            </ScrollToBottomButtonCircleContainer>
        </ScrollToBottomButtonContainer>
    );
}

function UserMessage({ message }: { message: ProductDiscoveryConversationMessageWithProducts }) {
    return (
        <UserMessageContainer>
            <MessageUserText>{message.content}</MessageUserText>
        </UserMessageContainer>
    );
}

function AssistantMessage({
    message,
    apolloClient,
    merchantIdToMerchantNameMap,
    conversationId,
    userFeedbackMap,
    setUserFeedbackMap,
}: {
    message: ProductDiscoveryConversationMessageWithProducts;
    apolloClient: ApolloClient | undefined;
    merchantIdToMerchantNameMap: MerchantIdToMerchantNameMap;
    conversationId: string;
    userFeedbackMap: { [id: string]: 'positive' | 'negative' | null };
    setUserFeedbackMap: React.Dispatch<React.SetStateAction<{ [id: string]: 'positive' | 'negative' | null }>>;
}) {
    const texts = getLocalizedTexts();
    return (
        <AssistantMessageContainer>
            <AssistantMessageTextContainer>
                <JokoLogoMessageImage src={logo} />
                {message.content ? (
                    <MessageAssistantText>{message.content}</MessageAssistantText>
                ) : (
                    // TODO: refactor to remove React Native components
                    <GradientText text={texts.productDiscovery.thinkingPlaceholder} fontSize={isMobileDevice ? 16 : 18} />
                )}
            </AssistantMessageTextContainer>
            {message.merchantProductOffers?.length ? (
                <SuggestedMerchantProductOffers
                    {...{ offers: message.merchantProductOffers, merchantIdToMerchantNameMap }}
                />
            ) : null}
            {message.merchantProductOffers?.length && apolloClient ? (
                <UserFeedback
                    {...{
                        client: apolloClient,
                        conversationId,
                        messageId: message.messagePosition,
                        userFeedbackMap,
                        setUserFeedbackMap,
                    }}
                />
            ) : null}
        </AssistantMessageContainer>
    );
}

function SuggestedMerchantProductOffers({
    offers,
    merchantIdToMerchantNameMap,
}: {
    offers: MerchantProductOffer[];
    merchantIdToMerchantNameMap: MerchantIdToMerchantNameMap;
}) {
    return (
        <SuggestedMerchantProductOffersContainer>
            {offers.map((offer) => (
                <MerchantProductOfferCard key={offer.merchantProductOfferId} {...{ offer, merchantIdToMerchantNameMap }} />
            ))}
        </SuggestedMerchantProductOffersContainer>
    );
}

function MerchantProductOfferCard({
    offer,
    merchantIdToMerchantNameMap,
}: {
    offer: MerchantProductOffer;
    merchantIdToMerchantNameMap: MerchantIdToMerchantNameMap;
}) {
    const [isImageLoading, setIsImageLoading] = React.useState(true);
    if (!offer.processedWebpageUrl) return null;
    const merchantNameToDisplay =
        offer.merchantId && merchantIdToMerchantNameMap[offer.merchantId]
            ? merchantIdToMerchantNameMap[offer.merchantId]
            : offer.contextualInformation.originalMerchantName;
    return (
        <ProductCardContainer onClick={() => window.open(offer.processedWebpageUrl as string, '_blank')}>
            <ProductCardContent>
                {isImageLoading ? (
                    <ProductImageContainer {...{ isImageLoading, isImagePlaceholder: true }}>
                        {/* TODO: refactor to remove React Native components */}
                        <AnimatedGradientBackground />
                    </ProductImageContainer>
                ) : null}
                <ProductImageContainer {...{ isImageLoading }}>
                    <ProductImage src={offer.mainImageUrls.defaultSize} onLoad={() => setIsImageLoading(false)} />
                </ProductImageContainer>
                <ProductTextContainer>
                    <ProductPriceText>
                        {formatCurrencyAmount(offer.priceInformation.displayPriceAmount.valueInCents / 100)}
                    </ProductPriceText>
                    <ProductTitleText>{offer.originalTitle}</ProductTitleText>
                    {merchantNameToDisplay ? (
                        <ProductLinkContainer>
                            <ProductLinkText>{merchantNameToDisplay}</ProductLinkText>
                            <LinkArrowImage src={productLinkArrow} />
                        </ProductLinkContainer>
                    ) : null}
                </ProductTextContainer>
            </ProductCardContent>
        </ProductCardContainer>
    );
}

function InputBar({
    inputText,
    setInputText,
    inputTextRef,
    setIsInputBarFocused,
    sendMessage,
    isAssistantThinking,
    setBottomPositionScrollButton,
}: {
    inputText: string;
    setInputText: (text: string) => void;
    inputTextRef: React.RefObject<HTMLTextAreaElement>;
    setIsInputBarFocused: (isFocused: boolean) => void;
    sendMessage: (suggestedMessage?: string) => void;
    isAssistantThinking: boolean;
    setBottomPositionScrollButton: (value: number) => void;
}) {
    const inputBarContainerRef = React.useRef<HTMLDivElement | null>(null);
    const texts = getLocalizedTexts().productDiscovery;
    const [isSendButtonEnabled, setIsSendButtonEnabled] = React.useState(false);
    React.useEffect(() => {
        if (isAssistantThinking) setIsSendButtonEnabled(false);
        else setIsSendButtonEnabled(inputText.trim().length > 0);
    }, [inputText, isAssistantThinking]);
    useAdaptInputBarHeight({
        inputText,
        inputTextRef,
        inputBarContainerRef,
        setBottomPositionScrollButton,
    });
    return (
        <InputBarContainer ref={inputBarContainerRef}>
            <InputText
                ref={inputTextRef}
                rows={1}
                value={inputText}
                onChange={(event) => setInputText(event.target.value)}
                placeholder={texts.inputPlaceholder}
                onKeyDown={(event) => {
                    if (event.key === 'Enter' && !event.shiftKey) {
                        sendMessage();
                        event.preventDefault(); // Prevent the default behavior of adding a new line
                    }
                }}
                onFocus={() => setIsInputBarFocused(true)}
                // We use a timeout to ensure blur click events are processed before showing suggestions again, preventing accidental selection when the input loses focus
                onBlur={() => setTimeout(() => setIsInputBarFocused(false), 0)}
            />
            <SendButtonContainer
                {...{ isSendButtonEnabled }}
                onClick={() => {
                    if (isSendButtonEnabled) sendMessage();
                }}>
                <ArrowSendButtonImage src={arrowHeadUp} />
            </SendButtonContainer>
        </InputBarContainer>
    );
}

function useAdaptInputBarHeight({
    inputText,
    inputTextRef,
    inputBarContainerRef,
    setBottomPositionScrollButton,
}: {
    inputText: string;
    inputTextRef: React.RefObject<HTMLTextAreaElement>;
    inputBarContainerRef: React.RefObject<HTMLDivElement>;
    setBottomPositionScrollButton: (value: number) => void;
}) {
    React.useEffect(() => {
        const textarea = inputTextRef.current;
        const inputBarContainer = inputBarContainerRef.current;
        if (!textarea || !inputBarContainer) return;
        textarea.style.height = 'auto';
        const newTextAreaHeight = Math.min(
            textarea.scrollHeight,
            isMobileDevice
                ? INPUT_BAR_MAXIMUM_HEIGHT_MOBILE - INPUT_BAR_PADDING_MOBILE
                : INPUT_BAR_MAXIMUM_HEIGHT_DESKTOP - INPUT_BAR_PADDING_DESKTOP
        );
        textarea.style.height = `${newTextAreaHeight}px`;
        const inputBarContainerHeight = Math.max(
            newTextAreaHeight + (isMobileDevice ? INPUT_BAR_PADDING_MOBILE : INPUT_BAR_PADDING_DESKTOP),
            isMobileDevice ? INPUT_BAR_MINIMUM_HEIGHT_MOBILE : INPUT_BAR_MINIMUM_HEIGHT_DESKTOP
        );
        inputBarContainer.style.height = `${inputBarContainerHeight}px`;
        inputBarContainer.style.minHeight = `${inputBarContainerHeight}px`;
        setBottomPositionScrollButton(
            inputBarContainerHeight +
                (isMobileDevice
                    ? INPUT_BAR_MARGIN_BOTTOM_MOBILE + SPACE_BETWEEN_SCROLL_TO_BOTTOM_BUTTON_AND_INPUT_BAR_MOBILE
                    : INPUT_BAR_MARGIN_BOTTOM_DESKTOP + SPACE_BETWEEN_SCROLL_TO_BOTTOM_BUTTON_AND_INPUT_BAR_DESKTOP)
        );
    }, [inputText]);
}

function UserFeedback({
    client,
    conversationId,
    messageId,
    userFeedbackMap,
    setUserFeedbackMap,
}: {
    client: ApolloClient;
    conversationId: string;
    messageId: string;
    userFeedbackMap: { [id: string]: 'positive' | 'negative' | null };
    setUserFeedbackMap: React.Dispatch<React.SetStateAction<{ [id: string]: 'positive' | 'negative' | null }>>;
}) {
    const texts = getLocalizedTexts().productDiscovery;
    const onClickPositiveFeedback = () => {
        if (userFeedbackMap[messageId] === 'positive') return;
        setUserFeedbackMap((prevFeedback) => ({
            ...prevFeedback,
            [messageId]: 'positive',
        }));
        logUserEventUtil(client, {
            type: 'sentProductDiscoverySuggestionsFeedback',
            payload: { conversationId, messageId, feedbackType: 'positive' },
        });
    };
    const onClickNegativeFeedback = () => {
        if (userFeedbackMap[messageId] === 'negative') return;
        setUserFeedbackMap((prevFeedback) => ({
            ...prevFeedback,
            [messageId]: 'negative',
        }));
        logUserEventUtil(client, {
            type: 'sentProductDiscoverySuggestionsFeedback',
            payload: { conversationId, messageId, feedbackType: 'negative' },
        });
    };
    return (
        <UserFeedbackContainer>
            <FeedbackQuestionText>{texts.feedbackQuestion}</FeedbackQuestionText>
            <ThumbImageContainer
                style={{ marginLeft: isMobileDevice ? 6 : 8, marginRight: 7 }}
                onClick={onClickPositiveFeedback}>
                <Thumb isSelected={userFeedbackMap[messageId] === 'positive'} orientation="up" />
            </ThumbImageContainer>
            <ThumbImageContainer onClick={onClickNegativeFeedback}>
                <Thumb isSelected={userFeedbackMap[messageId] === 'negative'} orientation="down" />
            </ThumbImageContainer>
        </UserFeedbackContainer>
    );
}

function LoadingPage() {
    return (
        <View style={{ height: '100vh', justifyContent: 'center' }}>
            <ActivityIndicator size="large" color={color.alto} />
        </View>
    );
}

type ProductDiscoveryContentWidthScale = '474px' | '630px' | '710px';

type ProductDiscoveryContentWidth = Partial<Record<Size, ProductDiscoveryContentWidthScale>>;

const productDiscoveryContentWidths: ProductDiscoveryContentWidth = {
    medium: '474px',
    large: '630px',
    xxLarge: '710px',
};

const INPUT_BAR_MARGIN_BOTTOM_DESKTOP = 24;
const INPUT_BAR_MARGIN_BOTTOM_MOBILE = 16;
const INPUT_BAR_MINIMUM_HEIGHT_DESKTOP = 64;
const INPUT_BAR_MINIMUM_HEIGHT_MOBILE = 44;
const INPUT_BAR_MAXIMUM_HEIGHT_DESKTOP = 142;
const INPUT_BAR_MAXIMUM_HEIGHT_MOBILE = 111;
const INPUT_BAR_PADDING_DESKTOP = 32;
const INPUT_BAR_PADDING_MOBILE = 16;
const SPACE_BETWEEN_SCROLL_TO_BOTTOM_BUTTON_AND_INPUT_BAR_DESKTOP = 16;
const SPACE_BETWEEN_SCROLL_TO_BOTTOM_BUTTON_AND_INPUT_BAR_MOBILE = 16;

const MainContainer = styled.div<{
    viewportHeight: number;
    isMobileDevice: boolean;
}>`
    display: flex;
    flex-direction: row;
    height: ${({ viewportHeight }) => viewportHeight}px;
    position: relative;
`;

const Overlay = styled.div<{ isMobileDevice: boolean; shouldShowConversationHistory: boolean }>`
    display: ${({ isMobileDevice, shouldShowConversationHistory }) =>
        isMobileDevice && shouldShowConversationHistory ? 'block' : 'none'};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    height: 100%;
    opacity: ${({ shouldShowConversationHistory }) => (shouldShowConversationHistory ? 0.3 : 0)};
    background: ${colors.background.primary};
    pointer-events: ${({ shouldShowConversationHistory }) => (shouldShowConversationHistory ? 'auto' : 'none')};
`;

const LandingPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    width: 100%;
    padding-top: 144px;
    margin-bottom: 16px;
    overflow-x: hidden;
    overflow-y: auto;

    @media (min-width: ${deviceWidthMeasurements.small}) & (max-height: 600px) {
        padding-top: 24px;
    }
    @media (max-width: ${deviceWidthMeasurements.small}) {
        width: calc(100% - 32px);
        padding-top: 0px;
        margin: 0px 16px;
    }

    ${NO_SCROLLBAR_CSS_MIXIN}
`;

const LandingPageInnerContainer = styled.div<{ isInputBarFocused: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: ${productDiscoveryContentWidths.xxLarge};

    @media (max-width: ${deviceWidthMeasurements.large}) {
        max-width: ${productDiscoveryContentWidths.large};
    }
    @media (max-width: ${deviceWidthMeasurements.medium}) {
        max-width: ${productDiscoveryContentWidths.medium};
    }
    @media (max-width: ${deviceWidthMeasurements.small}) {
        display: ${({ isInputBarFocused }) => (isInputBarFocused ? 'none' : 'flex')};
    }
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
`;

const MessagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 12px;
    overflow-x: hidden;
    overflow-y: auto;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        width: calc(100% - 32px);
        padding: 0 16px;
    }

    ${NO_SCROLLBAR_CSS_MIXIN}
`;

const MessagesInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: ${productDiscoveryContentWidths.xxLarge};

    @media (max-width: ${deviceWidthMeasurements.large}) {
        max-width: ${productDiscoveryContentWidths.large};
    }
    @media (max-width: ${deviceWidthMeasurements.medium}) {
        max-width: ${productDiscoveryContentWidths.medium};
    }
`;

const UserMessageSuggestionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: stretch;
    gap: 16px 16px;
`;

const UserMessageContainer = styled.div`
    max-width: 590px;
    align-self: flex-end;
    padding: 16px 24px 16px 24px;
    margin-bottom: 24px;
    border-radius: 12px;
    background-color: ${colors.background.light};

    @media (max-width: ${deviceWidthMeasurements.large}) {
        max-width: calc(${productDiscoveryContentWidths.large} - 120px);
    }
    @media (max-width: ${deviceWidthMeasurements.medium}) {
        max-width: calc(${productDiscoveryContentWidths.medium} - 120px);
    }

    @media (max-width: ${deviceWidthMeasurements.small}) {
        max-width: 256px;
    }
`;

const AssistantMessageContainer = styled.div`
    flex-direction: column;
    align-self: flex-start;
`;

const AssistantMessageTextContainer = styled.div`
    display: flex;
    flex-direction: row;
    max-width: calc(100vw - 32px);
    align-items: flex-start;
    margin-bottom: 32px;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        margin-bottom: 16px;
    }
`;

const SuggestedMerchantProductOffersContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(
        auto-fill,
        minmax(120px, 0fr)
    ); // This ensures items should not expand to fill additional space, which effectively fixes the width of each grid item to 120px
    grid-row-gap: 16px;
    justify-content: space-between;
    margin-left: 44px;
    margin-bottom: 32px;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        display: flex;
        position: relative;
        flex-direction: row;
        width: calc(100vw - 56px - 16px);
        gap: 8px;
        padding-left: 56px;
        padding-right: 16px;
        margin-bottom: 32px;
        transform: translateX(-60px); /* Adjust back to align to viewport edges */
        overflow-x: auto;

        ${NO_SCROLLBAR_CSS_MIXIN}
    }
`;

const ProductCardContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 120px;
    background-color: ${colors.background.default};
    ${CLICKABLE_ELEMENT_CSS_MIXIN}

    @media (min-width: ${deviceWidthMeasurements.small}) {
        &:hover {
            z-index: 1;
            border-radius: 16px;
            border: 1px solid ${colors.background.subtle};
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
            transform: scale(1.375, 1.2);
        }
    }
    @media (max-width: ${deviceWidthMeasurements.small}) {
        max-width: 144px;
    }
`;

const ProductCardContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const ProductImageContainer = styled.div<{ isImageLoading: boolean; isImagePlaceholder?: boolean }>`
    display: ${({ isImageLoading, isImagePlaceholder }) => (isImageLoading && !isImagePlaceholder ? 'none' : 'flex')};
    align-items: center;
    width: 120px;
    height: 120px;
    margin-bottom: 12px;

    @media (min-width: ${deviceWidthMeasurements.small}) {
        ${ProductCardContainer}:hover & {
            margin-bottom: 0px;
            width: calc(100% - 16px);
            padding-top: 8px;
            align-self: center;
        }
    }
    @media (max-width: ${deviceWidthMeasurements.small}) {
        width: 144px;
        height: 144px;
    }
`;

const ProductTextContainer = styled.div`
    @media (min-width: ${deviceWidthMeasurements.small}) {
        ${ProductCardContainer}:hover & {
            width: calc(135% - 16px);
            align-self: center;
            transform: scale(0.727, 0.833); // We reverse the scaling of the parent to keep the text at the same size
        }
    }
`;

const ProductLinkContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        margin-top: 4px;
    }
`;

const UserFeedbackContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin-left: 44px;
    margin-bottom: 28px;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        margin-left: 36px;
    }
`;

const ScrollToBottomButtonContainer = styled.div<{ bottomPositionScrollButton: number }>`
    position: fixed;
    bottom: ${({ bottomPositionScrollButton }) => bottomPositionScrollButton}px;
    align-self: center;
    width: 100%;
    max-width: ${productDiscoveryContentWidths.xxLarge};

    @media (max-width: ${deviceWidthMeasurements.large}) {
        max-width: ${productDiscoveryContentWidths.large};
    }
    @media (max-width: ${deviceWidthMeasurements.medium}) {
        max-width: ${productDiscoveryContentWidths.medium};
    }
    @media (max-width: ${deviceWidthMeasurements.small}) {
        max-width: 343px;
        min-width: 100%;
        margin-left: 32px;
    }
`;

const ScrollToBottomButtonCircleContainer = styled.div`
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 96px;
    border-width: 1px;
    border-color: #e3e3e3;
    border-style: solid;
    background-color: ${colors.background.default};
    ${CLICKABLE_ELEMENT_CSS_MIXIN}

    @media (max-width: ${deviceWidthMeasurements.small}) {
        width: 26px;
        height: 26px;
    }
`;

const InputBarContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    min-height: ${INPUT_BAR_MINIMUM_HEIGHT_DESKTOP}px;
    max-height: 142px;
    max-width: calc(${productDiscoveryContentWidths.xxLarge} - 16px - 24px); // We need to remove the padding
    width: calc(100% - 64px);
    padding-left: 16px;
    padding-right: 24px;
    margin-bottom: ${INPUT_BAR_MARGIN_BOTTOM_DESKTOP}px;
    border-radius: 16px;
    background-color: ${colors.background.subtle};

    @media (max-width: ${deviceWidthMeasurements.large}) {
        max-width: calc(${productDiscoveryContentWidths.large} - 16px - 24px);
    }
    @media (max-width: ${deviceWidthMeasurements.medium}) {
        max-width: calc(${productDiscoveryContentWidths.medium} - 16px - 24px);
    }
    @media (max-width: ${deviceWidthMeasurements.small}) {
        height: ${INPUT_BAR_MINIMUM_HEIGHT_DESKTOP}px;
        min-height: ${INPUT_BAR_MINIMUM_HEIGHT_MOBILE}px;
        margin-bottom: ${INPUT_BAR_MARGIN_BOTTOM_MOBILE}px;
        padding-left: 12px;
        padding-right: 16px;
    }
`;

const SendButtonContainer = styled.div<{ isSendButtonEnabled: boolean }>`
    display: flex;
    width: 32px;
    height: 32px;
    border-radius: 96px;
    justify-content: center;
    align-items: center;
    background-color: ${({ isSendButtonEnabled }) =>
        isSendButtonEnabled ? colors.background.primary : colors.background.tertiary};
    ${CLICKABLE_ELEMENT_CSS_MIXIN}
`;

const ThumbImageContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    ${CLICKABLE_ELEMENT_CSS_MIXIN}
`;

const JokoLogoLandingPageImage = styled.img`
    width: 54px;
    height: 54px;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        margin-top: 48px;
    }
`;

const JokoLogoMessageImage = styled.img`
    width: 32px;
    height: 32px;
    margin-right: 12px;
    flex-shrink: 0; // Prevent the logo from shrinking with the text

    @media (max-width: ${deviceWidthMeasurements.small}) {
        width: 28px;
        height: 28px;
    }
`;

const ArrowSendButtonImage = styled.img`
    width: 16px;
    height: 16px;
    object-fit: contain;
`;

const ProductImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 12px;
    transform-origin: center;

    @media (min-width: ${deviceWidthMeasurements.small}) {
        ${ProductCardContainer}:hover & {
            max-height: calc(100% - 20px);
            transform: scale(1, 1.15);
        }
    }
`;

const LinkArrowImage = styled.img`
    width: 11px;
    height: 11px;
    object-fit: contain;
`;

const ChevronDownImage = styled.img`
    width: 12px;
    height: 12px;
    object-fit: contain;
`;

const LandingPageTitleText = styled.div`
    margin-top: 32px;
    font-size: 32px;
    font-family: ${font.ambitBold};

    @media (max-width: ${deviceWidthMeasurements.small}) {
        margin-top: 28px;
        font-size: 22px;
    }
`;

const LandingPageSubtitleText = styled.div`
    margin-top: 24px;
    margin-bottom: 32px;
    font-size: 18px;
    text-align: center;
    font-family: ${font.ambitSemiBold};
    color: ${colors.content.secondary};

    @media (max-width: ${deviceWidthMeasurements.small}) {
        max-width: 360px;
        font-size: 16px;
        margin-top: 8px;
        margin-bottom: 24px;
    }
`;

const UserMessageSuggestionsText = styled.div`
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 32px;
    padding-right: 32px;
    font-size: 16px;
    font-family: ${font.ambitSemiBold};
    color: ${colors.content.secondary};
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.16);
    ${CLICKABLE_ELEMENT_CSS_MIXIN}

    &:hover {
        background-color: ${colors.background.subtle};
    }
`;

const ProductPriceText = styled.div`
    font-size: 18px;
    font-family: ${font.ambitBold};

    @media (max-width: ${deviceWidthMeasurements.small}) {
        font-size: 16px;
    }
`;

const ProductTitleText = styled.div`
    display: -webkit-box;
    margin-top: 8px;
    font-size: 16px;
    font-family: ${font.ambitSemiBold};
    color: ${colors.content.secondary};
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        font-size: 14px;
    }
`;

const ProductLinkText = styled.div`
    display: -webkit-box;
    margin-right: 8px;
    font-size: 14px;
    font-family: ${font.ambitSemiBold};
    color: ${colors.content.secondary};
    text-decoration-line: underline;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`;

const FeedbackQuestionText = styled.div`
    font-size: 16px;
    font-family: ${font.ambitSemiBold};
    line-height: 21px;
    color: ${colors.content.secondary};

    @media (max-width: ${deviceWidthMeasurements.small}) {
        font-size: 14px;
    }
`;

const MessageUserText = styled.div`
    font-size: 18px;
    font-family: ${font.ambitSemiBold};
    line-height: 21px;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        font-size: 16px;
    }
`;

const MessageAssistantText = styled.div`
    margin-top: 4px;
    font-size: 18px;
    font-family: ${font.ambitSemiBold};
    line-height: 25px;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        font-size: 16px;
        line-height: 22px;
    }
`;

const InputText = styled.textarea`
    flex: 1;
    max-width: 100%;
    max-height: 110px;
    height: 20px;
    margin-right: 16px;
    padding: 0;
    font-size: 18px;
    font-family: ${font.ambitSemiBold};
    border: none;
    resize: none;
    background-color: transparent;
    line-height: 21px;
    scrollbar-color: ${colors.border.secondary} ${colors.background.light};

    &:focus {
        outline: none;
    }

    @media (max-width: ${deviceWidthMeasurements.small}) {
        font-size: 16px;
        line-height: 19px;
    }
`;
