import React from 'react';
import styled from 'styled-components';

import { Feature, useHasFeature } from '../../lib/features/buildFeatureMap';
import { ProductDiscoveryConversationMessageWithProducts } from '../../lib/productDiscovery/conversationMessages';
import { CLICKABLE_ELEMENT_CSS_MIXIN } from '../../style/styleConstants';
import { deviceWidthMeasurements } from '../../style/size';
import { font } from '../../style/text';
import { isMobileDevice } from '../../style/utils';
import { colors } from '../../tokens/colors/colors';
import { SwitchDesignVersionButton } from './common/SwitchDesignVersionButton';

const editIcon = '/assets/images/icons/edit.svg';
const openConversationHistoryIcon = '/assets/images/icons/open.svg';
const settingsGear = '/assets/images/icons/settings-gear.svg';

export function Header({
    resetConversation,
    conversationId,
    shouldShowConversationHistory,
    setShouldShowConversationHistory,
    inputTextRef,
}: {
    resetConversation: ({
        conversationToReopen,
    }: {
        conversationToReopen?: {
            conversationId: string;
            messages: ProductDiscoveryConversationMessageWithProducts[];
        };
    }) => void;
    conversationId: string;
    shouldShowConversationHistory: boolean;
    setShouldShowConversationHistory: (value: boolean) => void;
    inputTextRef: React.RefObject<HTMLTextAreaElement | null>;
}) {
    const shouldShowConversationId = useHasFeature(Feature.showConversationIdInProductDiscovery);
    const [shouldShowSwitchDesignVersionButton, setShouldShowSwitchDesignVersionButton] = React.useState(false);
    return (
        <HeaderContainer>
            {!shouldShowConversationHistory ? (
                <div style={{ display: 'flex' }}>
                    <HeaderImageContainer
                        style={{ marginRight: 24 }}
                        onClick={() => {
                            resetConversation({});
                            inputTextRef.current?.focus();
                        }}>
                        <HeaderImage src={editIcon} />
                    </HeaderImageContainer>
                    <HeaderImageContainer onClick={() => setShouldShowConversationHistory(!shouldShowConversationHistory)}>
                        <HeaderImage src={openConversationHistoryIcon} />
                    </HeaderImageContainer>
                </div>
            ) : (
                <div /> // We use an empty div to benefit from the "space-between" alignment
            )}
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <BetaVersionText>{`Beta${
                    shouldShowConversationId && conversationId
                        ? ' - Conversation Id:' + (isMobileDevice ? '\n' : ' ') + conversationId
                        : ''
                }`}</BetaVersionText>
                <HeaderImage
                    src={settingsGear}
                    draggable={false}
                    onClick={() => setShouldShowSwitchDesignVersionButton(!shouldShowSwitchDesignVersionButton)}
                />
                {shouldShowSwitchDesignVersionButton ? (
                    <SwitchDesignVersionButton {...{ isLegacyDesign: true, conversationId }} />
                ) : null}
            </div>
        </HeaderContainer>
    );
}

const HeaderContainer = styled.div`
    display: flex;
    min-height: 48px;
    flex-direction: row;
    margin: 0px 24px;
    margin-top: 4px;
    justify-content: space-between;
    align-items: center;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        min-height: 36px;
        max-height: 36px;
        margin-left: 16px;
        margin-right: 16px;
        padding: 8px 0px;
    }
`;

export const HeaderImageContainer = styled.div`
    display: flex;
    width: 48px;
    height: 48px;
    align-items: center;
    justify-content: center;
    border-radius: 96px;
    ${CLICKABLE_ELEMENT_CSS_MIXIN}

    &:active {
        background-color: ${colors.background.subtle};
    }

    @media (min-width: ${deviceWidthMeasurements.small}) {
        &:hover {
            background-color: ${colors.background.subtle};
            cursor: pointer;
        }
    }
    @media (max-width: ${deviceWidthMeasurements.small}) {
        height: 36px;
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }
`;

export const HeaderImage = styled.img`
    width: 24px;
    height: 24px;
    ${CLICKABLE_ELEMENT_CSS_MIXIN}
`;

const BetaVersionText = styled.div`
    margin-right: 12px;
    font-size: 14px;
    font-family: ${font.ambitRegular};
    color: ${colors.content.secondary};

    @media (max-width: ${deviceWidthMeasurements.small}) {
        font-size: 12px;
        text-align: right;
        white-space: pre-wrap;
    }
`;
