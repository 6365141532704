import React from 'react';
import styled from 'styled-components';

import { ProductDiscoveryConversationMessageWithProducts } from '../../../lib/productDiscovery/conversationMessages';
import {
    CLICKABLE_ELEMENT_CSS_MIXIN,
    NON_HIGHLIGHTABLE_NOR_DRAGGABLE_ELEMENT_CSS_MIXIN,
} from '../../../style/styleConstants';
import { deviceWidthMeasurements } from '../../../style/size';
import { font } from '../../../style/text';
import { colors } from '../../../tokens/colors/colors';
import { SwitchDesignVersionButton } from './SwitchDesignVersionButton';
import { InfoTooltip } from './InfoTooltip';

const editIcon = '/assets/images/icons/edit.svg';
const jokoLogo = '/assets/images/logos/logo-with-title.png';
const openConversationHistoryIcon = '/assets/images/icons/open.svg';
const settingsGear = '/assets/images/icons/settings-gear.svg';

export function Header({
    resetConversation,
    conversationId,
    shouldShowConversationHistory,
    setShouldShowConversationHistory,
    shouldShowInfoTooltip,
    setShouldShowInfoTooltip,
    inputTextRef,
}: {
    resetConversation: ({
        conversationToReopen,
    }: {
        conversationToReopen?: {
            conversationId: string;
            messages: ProductDiscoveryConversationMessageWithProducts[];
        };
    }) => void;
    conversationId: string;
    shouldShowConversationHistory: boolean;
    setShouldShowConversationHistory: (value: boolean) => void;
    shouldShowInfoTooltip: boolean;
    setShouldShowInfoTooltip: (value: boolean) => void;
    inputTextRef: React.RefObject<HTMLTextAreaElement | null>;
}) {
    const [shouldShowSwitchDesignVersionButton, setShouldShowSwitchDesignVersionButton] = React.useState(false);
    return (
        <HeaderContainer>
            <LeftContainer>
                <HeaderImageContainer
                    style={{ marginRight: 24 }}
                    onClick={() => {
                        resetConversation({});
                        inputTextRef.current?.focus();
                    }}>
                    <HeaderImage src={editIcon} draggable={false} />
                </HeaderImageContainer>
                <HeaderImageContainer onClick={() => setShouldShowConversationHistory(!shouldShowConversationHistory)}>
                    <HeaderImage src={openConversationHistoryIcon} draggable={false} />
                </HeaderImageContainer>
            </LeftContainer>
            <CenterContainer>
                <JokoLogo src={jokoLogo} draggable={false} />
            </CenterContainer>
            <RightContainer>
                <BetaVersionText>{'Beta'}</BetaVersionText>
                <InfoTooltip {...{ shouldShowInfoTooltip, setShouldShowInfoTooltip }} />
                <HeaderImageContainer
                    onClick={() => setShouldShowSwitchDesignVersionButton(!shouldShowSwitchDesignVersionButton)}>
                    <HeaderImage src={settingsGear} draggable={false} />
                </HeaderImageContainer>
                {shouldShowSwitchDesignVersionButton ? (
                    <SwitchDesignVersionButton {...{ isLegacyDesign: false, conversationId }} />
                ) : null}
            </RightContainer>
        </HeaderContainer>
    );
}

const HeaderContainer = styled.div`
    display: flex;
    min-height: 48px;
    flex-direction: row;
    margin: 0px 24px;
    margin-top: 4px;
    justify-content: space-between;
    align-items: center;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        min-height: 36px;
        max-height: 36px;
        margin-left: 16px;
        margin-right: 16px;
        padding: 8px 0px;
    }
`;

export const HeaderImageContainer = styled.div`
    display: flex;
    width: 48px;
    height: 48px;
    align-items: center;
    justify-content: center;
    border-radius: 96px;
    ${CLICKABLE_ELEMENT_CSS_MIXIN}

    &:active {
        background-color: ${colors.background.subtle};
    }

    @media (min-width: ${deviceWidthMeasurements.small}) {
        &:hover {
            background-color: ${colors.background.subtle};
            cursor: pointer;
        }
    }
    @media (max-width: ${deviceWidthMeasurements.small}) {
        height: 36px;
        width: 20px;
        height: 20px;
        margin-left: 10px;
    }
`;

const LeftContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    text-align: left;
`;

const CenterContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
`;

const RightContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: right;
    align-items: center;
`;

export const HeaderImage = styled.img`
    width: 24px;
    height: 24px;
    ${CLICKABLE_ELEMENT_CSS_MIXIN}
`;

const JokoLogo = styled.img`
    width: 94px;
    object-fit: contain;
    ${NON_HIGHLIGHTABLE_NOR_DRAGGABLE_ELEMENT_CSS_MIXIN}

    @media (max-width: ${deviceWidthMeasurements.small}) {
        width: 70px;
    }
`;

const BetaVersionText = styled.div`
    margin-right: 8px;
    font-size: 14px;
    font-family: ${font.ambitRegular};
    color: ${colors.content.secondary};

    @media (max-width: ${deviceWidthMeasurements.small}) {
        font-size: 12px;
        text-align: right;
        white-space: pre-wrap;
    }
`;
