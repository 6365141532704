import React from 'react';
import { useHistory, useParams } from 'react-router';
import { ActivityIndicator, View } from 'react-native';

import { UserProductDiscoveryConversationHistoryItem } from '../../api/graphql/fragments/productDiscovery';
import { getUserId } from '../../lib/common/cognito';
import { Feature, useHasFeature } from '../../lib/features/buildFeatureMap';
import {
    createUserAndAssistantMessages,
    decodeRedirectionUrlParams,
} from '../../lib/productDiscovery/redirectionFromExtension';
import {
    ProductDiscoveryConversationMessageWithProducts,
    useHandleConnectionWithAssistant,
    useResetConversation,
    useSendMessage,
    useSuggestedProductsDebugLogs,
} from '../../lib/productDiscovery/conversationMessages';
import color from '../../style/color';
import { isMobileDevice } from '../../style/utils';
import { MobileProductDiscoveryPage } from './mobile/MobileProductDiscoveryPage';
import { DesktopProductDiscoveryPage } from './desktop/DesktopProductDiscoveryPage';

function ProductDiscoveryPage() {
    const history = useHistory();
    React.useEffect(() => {
        document.title = 'Joko AI';
    }, []);
    const isProductDiscoveryEnabled = useHasFeature(Feature.productDiscoveryInWebApp);
    if (isProductDiscoveryEnabled) return <ProductDiscoveryContent />;
    if (isProductDiscoveryEnabled === undefined) return <LoadingPage />;
    history.push('/home');
    return null;
}

export default ProductDiscoveryPage;

function ProductDiscoveryContent() {
    const conversationParam = useParams<{ conversationId?: string }>();
    const conversationIdParam = conversationParam.conversationId === 'new' ? undefined : conversationParam.conversationId;
    const { urlParams } = useParams<{ urlParams?: string }>();
    const { userSearchQuery, assistantMessageContent, partialMerchantProductOffers } = decodeRedirectionUrlParams(
        urlParams || ''
    );
    const [messages, setMessages] = React.useState<ProductDiscoveryConversationMessageWithProducts[] | undefined>(
        conversationIdParam
            ? []
            : userSearchQuery && assistantMessageContent
            ? createUserAndAssistantMessages(userSearchQuery, assistantMessageContent)
            : undefined
    );
    const [firstMessageToSend, setFirstMessageToSend] = React.useState<string | undefined>(undefined);
    const [inputText, setInputText] = React.useState<string>('');
    const [conversationId, setConversationId] = React.useState<string>('');
    const webSocketRef = React.useRef<WebSocket | undefined>(undefined);
    const userId = React.useMemo(() => getUserId(), []);
    let messageCounterRef = React.useRef(0);
    const [isAssistantThinking, setIsAssistantThinking] = React.useState(false);
    const messagesListRef = React.useRef<HTMLDivElement | null>(null);
    const [failedToFetchConversation, setFailedToFetchConversation] = React.useState(false);
    const [isWebSocketConnectionOpen, setIsWebSocketConnectionOpen] = React.useState(false);
    const [selectedConversationHistoryItemId, setSelectedConversationHistoryItemId] = React.useState<string | undefined>(
        conversationIdParam
    );
    const [lastConversationHistoryItem, setLastConversationHistoryItem] = React.useState<
        UserProductDiscoveryConversationHistoryItem | undefined
    >(undefined);
    const [lastConversationTitle, setLastConversationTitle] = React.useState<string | undefined>(undefined);
    const { initializeWebSocket, closeWebSocket } = useHandleConnectionWithAssistant({
        userId,
        webSocketRef,
        setIsWebSocketConnectionOpen,
        messageCounterRef,
        messages,
        setMessages,
        setIsAssistantThinking,
        conversationId,
        setConversationId,
        partialMerchantProductOffers,
        setLastConversationHistoryItem,
        setLastConversationTitle,
    });
    const sendMessage = useSendMessage({
        userId,
        webSocketRef,
        isWebSocketConnectionOpen,
        messageCounterRef,
        messages,
        setMessages,
        conversationId,
        inputText,
        setInputText,
        initializeWebSocket,
        messagesListRef,
        setIsAssistantThinking,
        firstMessageToSend,
        setFirstMessageToSend,
    });
    const resetConversation = useResetConversation({
        conversationId,
        setMessages,
        messageCounterRef,
        messagesListRef,
        setIsAssistantThinking,
        setFirstMessageToSend,
        setConversationId,
        initializeWebSocket,
        closeWebSocket,
        setSelectedConversationHistoryItemId,
        setFailedToFetchConversation,
        setInputText,
        setLastConversationHistoryItem,
        setLastConversationTitle,
    });
    useSuggestedProductsDebugLogs({ messages });
    const props = {
        conversationId,
        resetConversation,
        messages,
        setMessages,
        messagesListRef,
        inputText,
        setInputText,
        selectedConversationHistoryItemId,
        setSelectedConversationHistoryItemId,
        failedToFetchConversation,
        setFailedToFetchConversation,
        sendMessage,
        isAssistantThinking,
        lastConversationHistoryItem,
        lastConversationTitle,
    };
    if (isMobileDevice) return <MobileProductDiscoveryPage {...props} />;
    return <DesktopProductDiscoveryPage {...props} />;
}

function LoadingPage() {
    return (
        <View style={{ height: '100vh', justifyContent: 'center' }}>
            <ActivityIndicator size="large" color={color.alto} />
        </View>
    );
}
