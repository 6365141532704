import React from 'react';
import { FetchPolicy, useReactiveVar } from '@apollo/react-hooks';

import { useApolloClient } from '../../api/config';
import { UserState } from '../../api/graphql/fragments/states';
import { AppFeature, FeatureAndStateQueryResponse, featureAndStateQuery } from '../../api/graphql/queries/featuresAndStates';
import { userFeaturesMapVar } from '../../api/graphql/reactiveVariables';
import { ApolloClient } from '../../api/graphql/client';
import { LocalStorageKey } from '../common/localStorage';

const mongoCompiler = require('mongo-query-compiler');

export enum Feature {
    useNewOfferConditionStyleInWebApp = 'use-new-offer-condition-style-in-web-app',
    useCashbackRatesMergingInWebApp = 'use-cashback-rates-merging-in-web-app',
    giftCardsAtRedemption = 'gift-cards-at-redemption',
    intercomInWebApp = 'intercom-in-web-app',
    productDiscoveryInWebApp = 'product-discovery-in-web-app',
    /**
     * The following feature flag activates the sandbox environment in the product discovery experience,
     * which allows developers to replace some key parts of the logic by values fetched from settings set in Joko Admin.
     */
    useProductDiscoverySandboxEnvironment = 'use-product-discovery-sandbox-environment',
}

export type UserFeaturesMap = { [featureId: string]: boolean };

export function useUserFeaturesMap(): UserFeaturesMap | undefined {
    return useReactiveVar(userFeaturesMapVar) ?? undefined;
}

export async function updateUserFeaturesMapVar(client: ApolloClient) {
    const userFeaturesMap = await fetchUserFeaturesMap(client);
    userFeaturesMapVar(userFeaturesMap);
}

async function fetchUserFeaturesMap(client: ApolloClient, fetchPolicy: FetchPolicy = 'network-only') {
    const featureAndStateQueryResponse = await client.query<FeatureAndStateQueryResponse>({
        query: featureAndStateQuery,
        fetchPolicy,
    });
    return buildUserFeaturesMapFromQueryResponse(featureAndStateQueryResponse.data);
}

function buildUserFeaturesMapFromQueryResponse(data: FeatureAndStateQueryResponse | undefined): UserFeaturesMap {
    let userFeaturesMap: UserFeaturesMap = {};
    if (data && data.user && data.user.states && data.appFeatures && data.appFeatures.items)
        userFeaturesMap = buildUserFeaturesMap(data.user.states, data.appFeatures.items);
    return userFeaturesMap;
}

function buildUserFeaturesMap(userStates: UserState[], appFeatures: AppFeature[]): UserFeaturesMap {
    const parsedUserStatesMap = buildParsedUserStatesMap(userStates);
    const userFeaturesMap: UserFeaturesMap = {};
    for (const feature of appFeatures) {
        const parsedCondition = JSON.parse(feature.condition);
        const featureStatus = mongoCompiler(parsedCondition)(parsedUserStatesMap);
        userFeaturesMap[feature.featureId] = featureStatus;
    }
    return userFeaturesMap;
}

type ParsedUserState = {
    stateKey: string;
    value: any;
};

type ParsedUserStatesMap = { [stateKey: string]: any };

function buildParsedUserStatesMap(userStates: UserState[]): ParsedUserStatesMap {
    const parsedUserStates: ParsedUserState[] = userStates.map((userState) => ({
        stateKey: userState.stateKey,
        value: userState.value ? JSON.parse(userState.value) : undefined,
    }));
    const parsedUserStatesMap: ParsedUserStatesMap = {};
    for (const userState of parsedUserStates) parsedUserStatesMap[userState.stateKey] = userState.value;
    return parsedUserStatesMap;
}

export function useHasFeature(feature: Feature): boolean | undefined {
    const client = useApolloClient();
    const [hasFeature, setHasFeature] = React.useState<boolean | undefined>(
        checkShouldRefreshLocalUserFeaturesMap() ? undefined : checkHasFeatureFromLocalStorage(feature)
    );
    React.useEffect(() => {
        if (!client || !checkShouldRefreshLocalUserFeaturesMap()) return;
        checkHasFeatureFromNetwork(client, feature).then(setHasFeature);
    }, [client]);
    return hasFeature;
}

function checkHasFeatureFromLocalStorage(feature: Feature): boolean {
    try {
        const userFeaturesMap = localStorage.getItem(LocalStorageKey.userFeaturesMap);
        return userFeaturesMap ? JSON.parse(userFeaturesMap)[feature] : undefined;
    } catch (error) {
        console.error('Error parsing user features map from local storage:', error);
        return false;
    }
}

const FEATURES_MAP_QUERY_THROTTLING_IN_MS = 60 * 60 * 1000; // 1 hour

function checkShouldRefreshLocalUserFeaturesMap(): boolean {
    const userFeaturesMapLastFetchedTimestampMs = Number(
        localStorage.getItem(LocalStorageKey.userFeaturesMapLastFetchedTimestampMs) || 0
    );
    return Date.now() - userFeaturesMapLastFetchedTimestampMs > FEATURES_MAP_QUERY_THROTTLING_IN_MS;
}

async function checkHasFeatureFromNetwork(client: ApolloClient, feature: Feature): Promise<boolean> {
    const userFeaturesMap = await fetchUserFeaturesMapAndSaveInLocalStorage(client);
    return !!userFeaturesMap?.[feature];
}

async function fetchUserFeaturesMapAndSaveInLocalStorage(client: ApolloClient): Promise<UserFeaturesMap | undefined> {
    const userFeaturesMap = await fetchUserFeaturesMap(client);
    localStorage.setItem(LocalStorageKey.userFeaturesMapLastFetchedTimestampMs, Date.now().toString());
    localStorage.setItem(LocalStorageKey.userFeaturesMap, JSON.stringify(userFeaturesMap));
    return userFeaturesMap;
}
