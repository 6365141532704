import { Size } from '../tokens/measurements/size';

export const HOME_SCREEN_WIDTH = 800;
export const NAVIGATION_BAR_HEIGHT = 72;

type DeviceWidthMeasurementsScale = '490px' | '740px' | '1024px' | '1280px' | '1440px';

type DeviceWidthMeasurements = Partial<Record<Size, DeviceWidthMeasurementsScale>>;

export const deviceWidthMeasurements: DeviceWidthMeasurements = {
    small: '490px',
    medium: '740px',
    large: '1024px',
    xLarge: '1280px',
    xxLarge: '1440px',
};
