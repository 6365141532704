import { MerchantProductOffer, ProductDiscoveryConversationMessageWithProducts } from './conversationMessages';

/**
 * Decodes URL parameters from a redirection URL and extracts relevant information.
 *
 * The expected structure of the URL parameters is as follows:
 * - `user`: The user's search query.
 * - `assistant`: The assistant's response content.
 * - `id{index}`: The merchant product offer ID for the product at the given position.
 * - `price{index}`: The price to display for the product at the given position. The price should be in the format "amount currency" (e.g. "100 €").
 */
export function decodeRedirectionUrlParams(urlParams: string) {
    const params = new URLSearchParams(urlParams);
    const userSearchQuery = decodeURIComponent(params.get('user') || '');
    const assistantMessageContent = decodeURIComponent(params.get('assistant') || '');
    const partialMerchantProductOffers: MerchantProductOffer[] = [];
    const numberOfMerchantProductOffers = Array.from(params.keys()).filter((key) => key.startsWith('id')).length;
    for (let index = 0; index < numberOfMerchantProductOffers; index++) {
        const merchantProductOfferId = decodeURIComponent(params.get(`id${index}`) || '');
        const priceToDisplay = decodeURIComponent(params.get(`price${index}`) || '');
        const [price, currency] = priceToDisplay.split(' ');
        partialMerchantProductOffers.push({
            merchantProductOfferId,
            priceInformation: {
                displayPriceAmount: {
                    valueInCents: parseFloat(price.replace(',', '.')) * 100,
                    currency: currency === '€' ? 'EUR' : 'USD',
                },
            },
            merchantId: null,
            originalTitle: '',
            processedWebpageUrl: null,
            mainImageUrls: {
                defaultSize: '',
            },
            contextualInformation: {
                originalMerchantName: null,
            },
        });
    }
    return {
        userSearchQuery,
        assistantMessageContent,
        partialMerchantProductOffers,
    };
}

export function createUserAndAssistantMessages(userSearchQuery: string, assistantMessageContent: string) {
    const userMessage = {
        messagePosition: '0',
        content: userSearchQuery,
        role: 'user',
        timestamp: Date.now(),
    };
    const assistantMessage = {
        messagePosition: '1',
        content: assistantMessageContent,
        role: 'assistant',
        timestamp: Date.now(),
    };
    return [userMessage, assistantMessage] as ProductDiscoveryConversationMessageWithProducts[];
}
