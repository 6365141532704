import gql from 'graphql-tag';

export const deleteConversationHistoryMutation = gql`
    mutation {
        deleteUserProductDiscoveryConversations
    }
`;

export interface deleteConversationHistoryResponse {
    __typename: 'Mutation';
    deleteUserProductDiscoveryConversations: boolean;
}
