import React from 'react';
import styled from 'styled-components';

import { UserProductDiscoveryConversationHistoryItem } from '../../../api/graphql/fragments/productDiscovery';
import { ProductDiscoveryConversationMessageWithProducts } from '../../../lib/productDiscovery/conversationMessages';
import {
    useFetchMessagesOnConversationReopen,
    useConversationHistoryItems,
    ConversationHistoryItemsByDate,
} from '../../../lib/productDiscovery/conversationHistory';
import { font } from '../../../style/text';
import { isMobileDevice, MAX_MOBILE_SCREEN_WIDTH } from '../../../style/utils';
import { colors } from '../../../tokens/colors/colors';
import { getLocalizedTexts } from '../../../Locales';
import { SpinningWheel } from '../../../components/common/SpinningWheel';
import { HeaderImage, HeaderImageContainer } from './Header';
import { Overlay } from './Overlay';
import { ConversationHistoryItem } from './ConversationsHistoryItem';
import { ConversationHistorySectionEmptyState } from './ConversationHistorySectionEmptyState';
import { DeleteConversationHistoryConfirmationModal } from './DeleteConversationHistoryConfirmationModal';

const editIcon = '/assets/images/icons/edit.svg';
const closeConversationHistoryIcon = '/assets/images/icons/close.svg';
const deleteHistoryIcon = '/assets/images/icons/bin.svg';

export function ConversationHistorySection({
    conversationId: currentConversationId,
    resetConversation,
    setMessages,
    inputTextRef,
    shouldShowConversationHistory,
    setShouldShowConversationHistory,
    selectedConversationHistoryItemId,
    setSelectedConversationHistoryItemId,
    setFailedToFetchConversation,
    lastConversationHistoryItem,
    lastConversationTitle,
}: {
    conversationId: string;
    resetConversation: ({
        conversationToReopen,
    }: {
        conversationToReopen?: {
            conversationId: string;
            messages: ProductDiscoveryConversationMessageWithProducts[];
        };
    }) => void;
    setMessages: (messages: ProductDiscoveryConversationMessageWithProducts[] | undefined) => void;
    inputTextRef: React.RefObject<HTMLTextAreaElement | null>;
    shouldShowConversationHistory: boolean;
    setShouldShowConversationHistory: (value: boolean) => void;
    selectedConversationHistoryItemId: string | undefined;
    setSelectedConversationHistoryItemId: (value: string | undefined) => void;
    setFailedToFetchConversation: (value: boolean) => void;
    lastConversationHistoryItem: UserProductDiscoveryConversationHistoryItem | undefined;
    lastConversationTitle: string | undefined;
}) {
    const {
        conversationHistoryItemsByDate,
        setConversationHistoryItemsByDate,
        fetchMore,
        checkCanFetchMore,
        isFetchingMore,
    } = useConversationHistoryItems(lastConversationHistoryItem);
    useFetchMessagesOnConversationReopen({
        selectedConversationHistoryItemId,
        resetConversation,
        inputTextRef,
        setFailedToFetchConversation,
    });
    const [shouldShowDeleteHistoryConfirmation, setShouldShowDeleteHistoryConfirmation] = React.useState(false);
    const [conversationToDeleteOnMobile, setConversationToDeleteOnMobile] = React.useState<string | undefined>(undefined);
    return (
        <ConversationHistorySectionContainer {...{ shouldShowConversationHistory, isMobileDevice }}>
            {shouldShowDeleteHistoryConfirmation ? (
                <DeleteConversationHistoryConfirmationModal
                    {...{
                        setShouldShowDeleteHistoryConfirmation,
                        setConversationHistoryItemsByDate,
                        resetConversation,
                    }}
                />
            ) : null}
            <Overlay
                isVisible={shouldShowDeleteHistoryConfirmation || conversationToDeleteOnMobile !== undefined}
                onClick={() => {
                    setShouldShowDeleteHistoryConfirmation(false);
                    setConversationToDeleteOnMobile(undefined);
                }}
            />
            <ConversationsHistoryHeaderContainer {...{ isMobileDevice }}>
                {shouldShowConversationHistory ? (
                    <>
                        <HeaderImageContainer
                            onClick={() => {
                                resetConversation({});
                                if (isMobileDevice) setShouldShowConversationHistory(false);
                                inputTextRef.current?.focus();
                            }}>
                            <HeaderImage src={editIcon} draggable={false} />
                        </HeaderImageContainer>
                        <HeaderImageContainer
                            onClick={() => setShouldShowConversationHistory(!shouldShowConversationHistory)}>
                            <HeaderImage src={closeConversationHistoryIcon} draggable={false} />
                        </HeaderImageContainer>
                    </>
                ) : null}
            </ConversationsHistoryHeaderContainer>
            <ConversationHistoryContent
                {...{
                    currentConversationId,
                    selectedConversationHistoryItemId,
                    setSelectedConversationHistoryItemId,
                    lastConversationTitle,
                    setMessages,
                    setFailedToFetchConversation,
                    conversationToDeleteOnMobile,
                    setConversationToDeleteOnMobile,
                    resetConversation,
                    setShouldShowDeleteHistoryConfirmation,
                    conversationHistoryItemsByDate,
                    setConversationHistoryItemsByDate,
                    checkCanFetchMore,
                    fetchMore,
                    isFetchingMore,
                }}
            />
        </ConversationHistorySectionContainer>
    );
}

function ConversationHistoryContent({
    currentConversationId,
    selectedConversationHistoryItemId,
    setSelectedConversationHistoryItemId,
    lastConversationTitle,
    setMessages,
    setFailedToFetchConversation,
    conversationToDeleteOnMobile,
    setConversationToDeleteOnMobile,
    resetConversation,
    setShouldShowDeleteHistoryConfirmation,
    conversationHistoryItemsByDate,
    setConversationHistoryItemsByDate,
    checkCanFetchMore,
    fetchMore,
    isFetchingMore,
}: {
    currentConversationId: string;
    selectedConversationHistoryItemId: string | undefined;
    setSelectedConversationHistoryItemId: (value: string | undefined) => void;
    lastConversationTitle: string | undefined;
    setMessages: (messages: ProductDiscoveryConversationMessageWithProducts[] | undefined) => void;
    setFailedToFetchConversation: (value: boolean) => void;
    conversationToDeleteOnMobile: string | undefined;
    setConversationToDeleteOnMobile: (value: string | undefined) => void;
    resetConversation: ({
        conversationToReopen,
    }: {
        conversationToReopen?: {
            conversationId: string;
            messages: ProductDiscoveryConversationMessageWithProducts[];
        };
    }) => void;
    setShouldShowDeleteHistoryConfirmation: (value: boolean) => void;
    conversationHistoryItemsByDate: ConversationHistoryItemsByDate | undefined;
    setConversationHistoryItemsByDate: (value: ConversationHistoryItemsByDate) => void;
    checkCanFetchMore: () => boolean;
    fetchMore: () => void;
    isFetchingMore: boolean;
}) {
    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        if (scrollHeight - scrollTop - clientHeight <= 10 && checkCanFetchMore()) fetchMore();
    };
    const texts = getLocalizedTexts().productDiscovery.conversationHistory;
    if (conversationHistoryItemsByDate === undefined)
        return (
            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                <SpinningWheel {...{ width: 18, height: 18 }} />
            </div>
        );
    if (!conversationHistoryItemsByDate.length) return <ConversationHistorySectionEmptyState />;
    return (
        <>
            <ConversationsHistoryContainer {...{ isMobileDevice }} onScroll={handleScroll}>
                {conversationHistoryItemsByDate.map(({ date, conversations }, index) => {
                    return (
                        <div style={{ display: 'flex', flexDirection: 'column' }} key={date}>
                            <ConversationsHistoryDate>
                                <ConversationsHistoryText style={{ fontFamily: font.ambitBold, marginBottom: 8 }}>
                                    {date}
                                </ConversationsHistoryText>
                            </ConversationsHistoryDate>
                            {conversations.map((conversation) => {
                                return (
                                    <ConversationHistoryItem
                                        {...{
                                            currentConversationId,
                                            conversation,
                                            selectedConversationHistoryItemId,
                                            setSelectedConversationHistoryItemId,
                                            lastConversationTitle,
                                            setMessages,
                                            setFailedToFetchConversation,
                                            conversationToDeleteOnMobile,
                                            setConversationToDeleteOnMobile,
                                            conversationHistoryItemsByDate,
                                            setConversationHistoryItemsByDate,
                                            resetConversation,
                                        }}
                                    />
                                );
                            })}
                            {index === conversationHistoryItemsByDate.length - 1 ? (
                                isFetchingMore ? (
                                    <div style={{ marginTop: 16, marginBottom: 16 }}>
                                        <SpinningWheel {...{ width: 18, height: 18 }} />
                                    </div>
                                ) : null
                            ) : (
                                <ConversationsHistorySeparator {...{ isMobileDevice }} />
                            )}
                        </div>
                    );
                })}
            </ConversationsHistoryContainer>
            <ConversationHistoryFooter {...{ isMobileDevice }} onClick={() => setShouldShowDeleteHistoryConfirmation(true)}>
                <DeleteHistoryButton>
                    <DeleteHistoryIcon src={deleteHistoryIcon} />
                    {texts.deleteHistory.deleteButton}
                </DeleteHistoryButton>
            </ConversationHistoryFooter>
        </>
    );
}

const DESKTOP_PANEL_WIDTH = 384;
const MOBILE_PANEL_WIDTH = 278;

const ConversationHistorySectionContainer = styled.div<{
    shouldShowConversationHistory: boolean;
    isMobileDevice: boolean;
}>`
    display: flex;
    position: absolute;
    z-index: 3;
    flex-direction: column;
    flex: 0 0 auto;
    align-items: center;
    overflow-x: hidden;
    width: ${({ shouldShowConversationHistory }) =>
        shouldShowConversationHistory ? (isMobileDevice ? `${MOBILE_PANEL_WIDTH}px` : `${DESKTOP_PANEL_WIDTH}px`) : '0px'};
    height: 100%;
    transition: width 0.3s;
    background-color: ${colors.background.default};

    @media (max-width: ${MAX_MOBILE_SCREEN_WIDTH}) {
        background-color: ${colors.background.light};
    }
`;

const ConversationsHistoryHeaderContainer = styled.div<{ isMobileDevice: boolean }>`
    display: flex;
    width: ${({ isMobileDevice }) => (isMobileDevice ? 'calc(100% - 32px)' : 'calc(100% - 48px)')};
    min-height: ${({ isMobileDevice }) => (isMobileDevice ? '52px' : '48px')};
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
`;

const ConversationHistoryFooter = styled.div<{ isMobileDevice: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: ${({ isMobileDevice }) => (isMobileDevice ? 'calc(100% - 32px)' : 'calc(100% - 48px)')};
    padding: 24px;
`;

const DeleteHistoryButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: ${font.ambitBold};
    font-size: 16px;
    color: ${colors.content.default};
    white-space: nowrap;

    &:hover {
        cursor: pointer;
    }
`;

const ConversationsHistoryContainer = styled.div<{ isMobileDevice: boolean }>`
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    flex-grow: 1;
    width: 100%;
    margin-top: ${({ isMobileDevice }) => (isMobileDevice ? '16px' : '24px')};
    scrollbar-color: ${colors.border.secondary} ${colors.background.default};
`;

const ConversationsHistoryDate = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px;
`;

const ConversationsHistorySeparator = styled.div<{ isMobileDevice: boolean }>`
    height: 1px;
    margin: 0px 24px;
    margin-top: ${({ isMobileDevice }) => (isMobileDevice ? '8px' : '24px')};
    margin-bottom: ${({ isMobileDevice }) => (isMobileDevice ? '16px' : '32px')};
    background-color: ${colors.border.secondary};
`;

const ConversationsHistoryText = styled.div`
    display: -webkit-box;
    font-size: 16px;
    font-family: ${font.ambitSemiBold};
    color: ${colors.content.default};
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`;

const DeleteHistoryIcon = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 8px;
`;
