import gql from 'graphql-tag';

export const deleteConversationMutation = gql`
    mutation deleteUserProductDiscoveryConversation($conversationId: String!) {
        deleteUserProductDiscoveryConversation(conversationId: $conversationId)
    }
`;

export interface DeleteConversationResponse {
    __typename: 'Mutation';
    deletedConversationId: string;
}

export interface DeleteConversationMutationVariables {
    conversationId: string;
}
