import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../tokens/colors/colors';
import { font } from '../../../style/text';
import { getLocalizedTexts } from '../../../Locales';

const deleteConversationIcon = '/assets/images/icons/bin.svg';
const cancelIcon = '/assets/images/icons/cross-black.svg';

export function DeleteConversationConfirmationModal({
    handleClickDeleteConversation,
    setConversationToDeleteOnMobile,
}: {
    handleClickDeleteConversation: (event?: React.MouseEvent) => void;
    setConversationToDeleteOnMobile: (value: string | undefined) => void;
}) {
    const texts = getLocalizedTexts().productDiscovery.conversationHistory;
    const handleClickButtonDeleteConversation = () => {
        handleClickDeleteConversation();
        setConversationToDeleteOnMobile(undefined);
    };
    return (
        <DeleteConversationConfirmationModalContainer>
            <DeleteConversationButton onClick={handleClickButtonDeleteConversation}>
                <ButtonIcon src={deleteConversationIcon} />
                {texts.deleteConversation.toolTip}
            </DeleteConversationButton>
            <CancelButton onClick={() => setConversationToDeleteOnMobile(undefined)}>
                <ButtonIcon src={cancelIcon} />
                {texts.deleteConversation.cancelButton}
            </CancelButton>
        </DeleteConversationConfirmationModalContainer>
    );
}

const DeleteConversationConfirmationModalContainer = styled.div`
    position: fixed;
    z-index: 3;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 212px;
    border-radius: 12px;
    background-color: ${colors.background.default};
    transform: translate(-50%, -50%);
`;

const DeleteConversationButton = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 12px;
    border-bottom: 1px solid ${colors.border.secondary};
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: ${colors.background.default};
    font-family: ${font.ambitSemiBold};
    font-size: 16px;
    color: ${colors.content.default};
    white-space: nowrap;
    user-select: none;
`;

const CancelButton = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: ${colors.background.default};
    font-family: ${font.ambitSemiBold};
    font-size: 16px;
    color: ${colors.content.default};
    white-space: nowrap;
    user-select: none;
`;

const ButtonIcon = styled.img`
    width: 16px;
    margin-right: 12px;
`;
