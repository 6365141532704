import React from 'react';
import { View, StyleSheet, TouchableOpacity, Text, Image, ActivityIndicator, Modal, ViewStyle } from 'react-native';
import { useApolloClient, useQuery } from '@apollo/react-hooks';

import { getLocalizedTexts, formatCurrencyAmount } from '../../Locales';
import color from '../../style/color';
import { font } from '../../style/text';
import { useWindowSize } from '../../style/utils';
import { containerStyles } from '../../style/container';
import { HistoryRecord, HistoryRecordType } from '../../api/graphql/fragments/history';
import { RewardType } from '../../api/graphql/fragments/rewards';
import { SettingKey } from '../../api/graphql/fragments/settings';
import { userHistoryQuery, UserHistoryQueryResponse, UserHistoryQueryVariables } from '../../api/graphql/queries/history';
import { convertPointsToCurrencyAmount } from '../../lib/points/points';
import { getSetting } from '../../lib/settings/settings';
import { logAmplitudeEvent } from '../../lib/events/amplitudeEvents';
import { ProgressiveSectionList } from '../../components/common/ProgressiveList';
import { HoverableTouchableOpacity } from '../../components/common/HoverableComponents';

const moment = require('moment');

const clockIcon = { uri: '/assets/images/icons/clock-gray.svg' };
const historyIcon = { uri: '/assets/images/icons/history-black.svg' };
const crossIcon = { uri: '/assets/images/icons/cross-white-slim.svg' };
const banknoteWithDollarSignEmoji = { uri: '/assets/images/icons/apple-banknote-with-dollar-sign.png' };
const handshakeEmoji = { uri: '/assets/images/icons/apple-handshake.png' };
const partyPopperEmoji = { uri: '/assets/images/icons/apple-party-popper.png' };
const smilingFaceWithHearShapedEyesEmoji = { uri: '/assets/images/icons/apple-smiling-face-with-heart-shaped-eyes.png' };
const wrappedPresentEmoji = { uri: '/assets/images/icons/apple-wrapped-present.png' };
const emptyImage = { uri: '/assets/images/illustrations/undraw-empty.svg' };

type TabId = 'all' | 'pending';

function HistoryPage() {
    const texts = getLocalizedTexts().home.history;
    const [selectedTabId, setSelectedTabId] = React.useState<TabId>('all');
    return (
        <View style={styles.container}>
            <Text style={styles.textTitle}>{texts.title}</Text>
            <TabBar {...{ selectedTabId, setSelectedTabId }} />
            <HistoryItemList filterPendingRecords={selectedTabId === 'pending'} />
        </View>
    );
}

export default HistoryPage;

function TabBar({ selectedTabId, setSelectedTabId }: { selectedTabId: TabId; setSelectedTabId: (value: TabId) => void }) {
    const tabs = getTabs();
    return (
        <View style={styles.containerTabBar}>
            {tabs.map(({ id, title }) => (
                <HoverableTouchableOpacity
                    key={id}
                    style={[styles.containerTabBarItem, id === selectedTabId && styles.containerTabBarItemSelected]}
                    hoveredStyle={styles.containerTabBarItemHovered}
                    onPress={() => {
                        logAmplitudeEvent({ name: 'History - Clicked Tab', properties: { tab: id } });
                        setSelectedTabId(id);
                    }}>
                    <Text style={[styles.textTabBarItem, id === selectedTabId && styles.textTabBarItemSelected]}>
                        {title}
                    </Text>
                </HoverableTouchableOpacity>
            ))}
        </View>
    );
}

function getTabs(): { id: TabId; title: string }[] {
    const texts = getLocalizedTexts().home.history.tab;
    return [
        { id: 'all', title: texts.all },
        { id: 'pending', title: texts.pending },
    ];
}

function HistoryItemList({ filterPendingRecords }: { filterPendingRecords: boolean }) {
    const texts = getLocalizedTexts().home.history;
    const size = useWindowSize();
    const sections = useHistoryRecordSections(filterPendingRecords);
    const shouldRenderSectionTitles: boolean = checkShouldRenderSectionTitles(sections);
    const [dialogState, setDialogState] = React.useState<DialogState>({ isVisible: false });
    if (!sections)
        return (
            <View style={containerStyles.containerCenter}>
                <ActivityIndicator size={'large'} color={color.frenchGray} />
            </View>
        );
    if (sections.length === 0 && filterPendingRecords)
        return (
            <View style={{ height: (size.height || 0) * 0.7 }}>
                <Text style={styles.textPendingRecordsInformation}>{texts.information.pendingRecords}</Text>
                <View style={{ alignItems: 'center' }}>
                    <Image source={emptyImage} style={{ width: 250, height: 250, resizeMode: 'contain' }} />
                </View>
            </View>
        );
    return (
        <>
            <ProgressiveSectionList<HistoryRecord>
                style={{ height: (size.height || 0) * 0.7 }}
                sections={sections}
                keyExtractor={(item) => item.recordId}
                renderItem={({ item }) => <HistoryItem record={item} {...{ setDialogState }} />}
                renderSectionHeader={({ section: { sectionKey } }) =>
                    !shouldRenderSectionTitles ? (
                        <View style={{ height: 15 }} />
                    ) : (
                        <Text style={styles.textSectionTitle}>
                            {sectionKey === 'currentWeek'
                                ? texts.sectionTitle.currentWeek
                                : sectionKey === 'lastWeek'
                                ? texts.sectionTitle.lastWeek
                                : texts.sectionTitle.earlier}
                        </Text>
                    )
                }
                initialNumberOfItemsToDisplay={20}
                stickySectionHeadersEnabled={false}
                ListHeaderComponent={
                    filterPendingRecords ? (
                        <Text style={styles.textPendingRecordsInformation}>{texts.information.pendingRecords}</Text>
                    ) : null
                }
                ListFooterComponent={
                    !filterPendingRecords ? (
                        <Text style={styles.textCashbackDelayInformation}>{texts.information.cashbackDelay}</Text>
                    ) : (
                        <View style={{ height: 20 }} />
                    )
                }
            />
            <Dialog {...{ dialogState }} closeDialog={() => setDialogState({ ...dialogState, isVisible: false })} />
        </>
    );
}

type SectionKey = 'currentWeek' | 'lastWeek' | 'earlier';
const sectionKeys: SectionKey[] = ['currentWeek', 'lastWeek', 'earlier'];

function useHistoryRecordSections(
    filterPendingRecords: boolean
): { sectionKey: SectionKey; data: HistoryRecord[] }[] | undefined {
    const historyRecords = useHistoryRecords();
    const historyRecordSections = React.useMemo(() => {
        if (!historyRecords) return undefined;
        const startOfCurrentWeek = moment().startOf('week').unix();
        const startOfLastWeek = moment().startOf('week').subtract(1, 'week').unix();
        const historyRecordsSectionMap: { [sectionKey: string]: HistoryRecord[] } = {};
        for (const historyRecord of historyRecords) {
            if (filterPendingRecords && !historyRecord.pending) continue;
            const key: SectionKey =
                historyRecord.timestamp > startOfCurrentWeek
                    ? 'currentWeek'
                    : historyRecord.timestamp > startOfLastWeek
                    ? 'lastWeek'
                    : 'earlier';
            if (!historyRecordsSectionMap[key]) historyRecordsSectionMap[key] = [];
            historyRecordsSectionMap[key].push(historyRecord);
        }
        const historyRecordSections = [];
        for (const sectionKey of sectionKeys)
            if (historyRecordsSectionMap[sectionKey]?.length > 0)
                historyRecordSections.push({
                    sectionKey,
                    data: historyRecordsSectionMap[sectionKey],
                });
        return historyRecordSections;
    }, [historyRecords, filterPendingRecords]);
    return historyRecordSections;
}

function useHistoryRecords(): HistoryRecord[] | undefined {
    const queryResponse = useQuery<UserHistoryQueryResponse, UserHistoryQueryVariables>(userHistoryQuery, {
        variables: { showUncollected: true, showReferralPoints: true },
        fetchPolicy: 'cache-and-network',
    });
    const historyRecords: HistoryRecord[] | undefined = React.useMemo(
        () => queryResponse.data?.user?.history || undefined,
        [queryResponse]
    );
    return historyRecords;
}

function checkShouldRenderSectionTitles(sections: { sectionKey: SectionKey; data: HistoryRecord[] }[] | undefined): boolean {
    if (sections) {
        for (const { sectionKey, data } of sections) if (sectionKey !== 'earlier' && data.length > 0) return true;
    }
    return false;
}

export const HistoryItem = React.memo(
    ({
        record,
        setDialogState,
        style,
    }: {
        record: HistoryRecord;
        setDialogState?: (state: DialogState) => void;
        style?: ViewStyle;
    }) => {
        const onPress = useOnPressAction(record, setDialogState);
        return (
            <TouchableOpacity style={[styles.containerHistoryItem, style]} disabled={!onPress} onPress={onPress}>
                <View style={containerStyles.containerRowLeftCenter}>
                    <View style={styles.containerHistoryItemIcon}>
                        <HistoryItemIcon {...{ record }} />
                    </View>
                    <HistoryItemDecorator {...{ record }} />
                    <HistoryItemTexts {...{ record }} />
                </View>
                <HistoryItemAmount {...{ record }} />
            </TouchableOpacity>
        );
    }
);

function HistoryItemIcon({ record }: { record: HistoryRecord }): JSX.Element | null {
    if (record.offer?.screen?.logoURL)
        return <Image source={{ uri: record.offer.screen.logoURL }} style={styles.imageHistoryItemIconLarge} />;
    if (record.bonus?.card?.imageURL)
        return <Image source={{ uri: record.bonus.card.imageURL }} style={styles.imageHistoryItemIconLarge} />;
    if (record.referralPoints) return <Image source={handshakeEmoji} style={styles.imageHistoryItemIconSmall} />;
    if (record.reward?.type)
        switch (record.reward.type) {
            case RewardType.contest:
                return <Image source={partyPopperEmoji} style={styles.imageHistoryItemIconSmall} />;
            case RewardType.bankTransfer:
                return <Image source={banknoteWithDollarSignEmoji} style={styles.imageHistoryItemIconSmall} />;
            case RewardType.giftCard:
                return <Image source={wrappedPresentEmoji} style={styles.imageHistoryItemIconSmall} />;
            case RewardType.donation:
                return <Image source={smilingFaceWithHearShapedEyesEmoji} style={styles.imageHistoryItemIconSmall} />;
        }
    return null;
}

function HistoryItemDecorator({ record }: { record: HistoryRecord }): JSX.Element | null {
    if (checkIsCashbackDeclined(record))
        return (
            <View style={styles.containerHistoryItemDeclinedIcon}>
                <Image source={crossIcon} style={{ height: 12, width: 12, resizeMode: 'contain' }} />
            </View>
        );
    else if (record.pending)
        return (
            <View style={styles.containerHistoryItemPendingIcon}>
                <Image source={clockIcon} style={{ height: 15, width: 15, resizeMode: 'contain' }} />
            </View>
        );
    else if (record.isRetroactive)
        return (
            <View style={styles.containerHistoryItemRetroactiveIcon}>
                <Image source={historyIcon} style={{ height: 19, width: 19, resizeMode: 'contain' }} />
            </View>
        );
    else return null;
}

function checkIsCashbackDeclined(record: HistoryRecord): boolean {
    return !!(record.affiliateTransaction || record.transaction) && record.points === 0;
}

function HistoryItemTexts({ record }: { record: HistoryRecord }): JSX.Element {
    const title = getHistoryItemTitle(record);
    const subtitle = getHistoryItemSubtitle(record);
    const isPenalty = checkIsPenalty(record);
    const formattedDate = formatDate(record);
    return (
        <View style={{ flex: 1 }}>
            <Text style={styles.textHistoryItemTitle}>{title}</Text>
            <Text style={styles.textHistoryItemSubtitle}>
                {subtitle && !isPenalty ? `${subtitle} • ${formattedDate}` : formattedDate}
            </Text>
        </View>
    );
}

function getHistoryItemTitle(historyRecord: HistoryRecord): string | undefined {
    const texts = getLocalizedTexts().home.history.item.title;
    if (historyRecord.offer?.screen?.title) return historyRecord.offer.screen.title;
    if (historyRecord.bonus?.card?.title) return historyRecord.bonus.card.title;
    if (historyRecord.referralPoints) return texts.referral;
    if (historyRecord.reward?.type)
        switch (historyRecord.reward.type) {
            case RewardType.contest:
                return texts.contest;
            case RewardType.bankTransfer:
                return texts.bankTransfer;
            case RewardType.giftCard:
                return texts.giftCard;
            case RewardType.donation:
                if (historyRecord.userReward?.donationDetails?.isAutoDonation) return texts.autoDonation;
                else return texts.donation;
        }
}

function getHistoryItemSubtitle(record: HistoryRecord): string | undefined {
    const texts = getLocalizedTexts().home.history.item.subtitle;
    if (record.bonus) return texts.bonus;
    if (record.transaction?.value)
        return formatCurrencyAmount(-record.transaction.value, { shouldNeverUseThreeDecimals: false });
    if (record.affiliateTransaction?.transactionAmount)
        return formatCurrencyAmount(record.affiliateTransaction.transactionAmount, { shouldNeverUseThreeDecimals: false });
    if (record.referralPoints?.refereeUserInfo?.firstName) return record.referralPoints.refereeUserInfo.firstName.trim();
    if (record.reward?.type)
        switch (record.reward.type) {
            case RewardType.contest:
                return texts.contest.trim();
            case RewardType.bankTransfer:
                return texts.bankTransfer({
                    amount: record.userReward?.bankTransferDetails?.amount || 0,
                });
            case RewardType.giftCard:
                return texts.giftCard({
                    value: convertPointsToCurrencyAmount(record.userReward?.points || 0),
                    name: record.reward?.screen?.title,
                });
            case RewardType.donation:
                return texts.donation({
                    value: convertPointsToCurrencyAmount(record.userReward?.points || 0),
                    name: record.reward?.screen?.title,
                });
        }
}

export function checkIsPenalty(record: HistoryRecord): boolean {
    return record.bonus ? record.points < 0 : false;
}

function formatDate(record: HistoryRecord): string {
    const texts = getLocalizedTexts().home.history.item.date;
    let timestamp = record.timestamp;
    if (record.transaction?.date) timestamp = moment(record.transaction.date).unix();
    if (record.affiliateTransaction?.purchasedAt) timestamp = record.affiliateTransaction.purchasedAt;
    const date: string = moment.unix(timestamp).format('YYYY-MM-DD');
    const today: string = moment().format('YYYY-MM-DD');
    const numberOfDaysAgo: number = moment(today).diff(moment(date), 'days');
    if (numberOfDaysAgo === 0) return texts.today;
    if (numberOfDaysAgo === 1) return texts.yesterday;
    if (numberOfDaysAgo >= 2 && numberOfDaysAgo <= 7) return texts.numberOfDaysAgo({ numberOfDaysAgo });
    return texts.date({ date: moment(date) });
}

function HistoryItemAmount({ record }: { record: HistoryRecord }) {
    const isRedeem = record.type === HistoryRecordType.redeem;
    const isPenalty = checkIsPenalty(record);
    const isPending = record.pending;
    if (checkIsCashbackDeclined(record)) return null;
    return (
        <View style={styles.containerHistoryItemAmount}>
            <Text
                style={[
                    styles.textHistoryItemCashback,
                    isRedeem && styles.textHistoryItemRedeem,
                    isPenalty && styles.textHistoryItemPenalty,
                    isPending && styles.textHistoryItemPendingCashback,
                ]}>
                {isRedeem ? '-' : ''}
                {formatCurrencyAmount(convertPointsToCurrencyAmount(record.points))}
            </Text>
        </View>
    );
}

function useOnPressAction(record: HistoryRecord, setDialogState?: (state: DialogState) => void): (() => void) | undefined {
    const texts = getLocalizedTexts().home.history.dialog.retroactiveCashback;
    const apolloClient = useApolloClient();
    if (record?.isRetroactive)
        return async () => {
            const setting = await getSetting(apolloClient, SettingKey.numberOngoingOfferRetroactiveDays, 'cache-first');
            if (setting && setDialogState) {
                setDialogState({
                    isVisible: true,
                    title: texts.title,
                    subtitle: texts.subtitle({
                        numberOngoingOfferRetroactiveDays: Number(setting.value),
                    }),
                    buttonText: texts.buttonText,
                });
            }
        };
    else return undefined;
}

export type DialogState = {
    isVisible: boolean;
    title?: string;
    subtitle?: string;
    buttonText?: string;
};

export function Dialog({ dialogState, closeDialog }: { dialogState: DialogState; closeDialog: () => void }) {
    return (
        <Modal visible={dialogState.isVisible} transparent>
            <View style={styles.containerDialogBackground}>
                <View style={styles.containerDialog}>
                    {dialogState.title ? <Text style={styles.textDialogTitle}>{dialogState.title}</Text> : null}
                    <Text style={styles.textDialogSubtitle}>{dialogState.subtitle}</Text>
                    <TouchableOpacity style={styles.containerDialogButton} onPress={() => closeDialog()}>
                        <Text style={styles.textDialogButton}>{dialogState.buttonText}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({
    container: {
        width: 600,
        borderRadius: 16,
        backgroundColor: 'white',
        overflow: 'hidden',
    },
    containerTabBar: {
        marginHorizontal: 40,
        paddingTop: 20,
        flexDirection: 'row',
    },
    containerTabBarItem: {
        marginRight: 20,
        paddingBottom: 5,
    },
    containerTabBarItemHovered: {
        opacity: 0.7,
    },
    containerTabBarItemSelected: {
        borderBottomColor: color.emerald,
        borderBottomWidth: 3,
    },
    containerHistoryItem: {
        marginHorizontal: 40,
        marginTop: 8,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    containerHistoryItemIcon: {
        height: 56,
        width: 56,
        borderRadius: 56,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 16,
        backgroundColor: color.linkWaterLight,
    },
    containerHistoryItemDeclinedIcon: {
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        height: 15,
        width: 15,
        borderRadius: 15,
        left: 38,
        top: 38,
        backgroundColor: color.flamingo,
    },
    containerHistoryItemPendingIcon: {
        position: 'absolute',
        left: 38,
        top: 38,
    },
    containerHistoryItemRetroactiveIcon: {
        position: 'absolute',
        left: 38,
        top: 38,
    },
    containerHistoryItemAmount: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    containerDialogBackground: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.3)',
    },
    containerDialog: {
        width: 540,
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: color.white,
        paddingHorizontal: 40,
        paddingBottom: 40,
        borderRadius: 10,
    },
    containerDialogButton: {
        paddingVertical: 14,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 60,
        backgroundColor: color.emerald,
    },
    textTitle: {
        fontFamily: font.ambitBlack,
        fontSize: 24,
        color: color.black,
        paddingHorizontal: 40,
        paddingTop: 25,
    },
    textTabBarItem: {
        fontFamily: font.ambitBlack,
        fontSize: 18,
        color: color.frenchGray,
    },
    textTabBarItemSelected: {
        color: color.emerald,
    },
    textSectionTitle: {
        marginHorizontal: 40,
        paddingTop: 25,
        paddingBottom: 7,
        fontFamily: font.ambitBlack,
        fontSize: 19,
        color: color.black,
    },
    textPendingRecordsInformation: {
        marginTop: 20,
        marginHorizontal: 40,
        fontFamily: font.ambitBold,
        fontSize: 12,
        color: color.grayLight,
    },
    textCashbackDelayInformation: {
        textAlign: 'center',
        margin: 40,
        fontFamily: font.ambitBold,
        fontSize: 12,
        color: color.grayLight,
    },
    textHistoryItemTitle: {
        fontFamily: font.ambitBold,
        fontSize: 14,
        color: color.black,
    },
    textHistoryItemSubtitle: {
        marginTop: 3,
        fontFamily: font.ambitBold,
        fontSize: 12,
        color: color.grayLight,
    },
    textHistoryItemCashback: {
        fontFamily: font.ambitBlack,
        fontSize: 14,
        color: color.emerald,
        marginLeft: 3,
    },
    textHistoryItemRedeem: {
        color: color.coral,
    },
    textHistoryItemPenalty: {
        color: color.coral,
    },
    textHistoryItemPendingCashback: {
        color: color.frenchGray,
    },
    imageHistoryItemIconLarge: {
        width: 32,
        height: 32,
        resizeMode: 'contain',
    },
    imageHistoryItemIconSmall: {
        width: 24,
        height: 24,
        resizeMode: 'contain',
    },
    textDialogTitle: {
        marginTop: 40,
        marginHorizontal: 25,
        fontFamily: font.ambitBold,
        fontSize: 24,
        color: color.black,
        textAlign: 'center',
    },
    textDialogSubtitle: {
        marginVertical: 30,
        marginHorizontal: 15,
        fontFamily: font.ambitSemiBold,
        fontSize: 16,
        color: color.black,
        textAlign: 'center',
    },
    textDialogButton: {
        fontFamily: font.ambitBold,
        fontSize: 14,
        color: color.white,
    },
});
