import React from 'react';
import styled from 'styled-components';

import { scrollToBottom } from '../../../lib/productDiscovery/conversationMessages';
import { CLICKABLE_ELEMENT_CSS_MIXIN } from '../../../style/styleConstants';
import { deviceWidthMeasurements } from '../../../style/size';

import { colors } from '../../../tokens/colors/colors';

const chevronGrayDown = '/assets/images/icons/chevron-gray-down-new.svg';

export function ScrollToBottomButton({
    listRef,
    bottomPositionScrollButton,
}: {
    listRef: React.RefObject<any>;
    bottomPositionScrollButton: number;
}) {
    return (
        <ScrollToBottomButtonContainer {...{ bottomPositionScrollButton }}>
            <ScrollToBottomButtonCircleContainer onClick={() => scrollToBottom(listRef, 'smooth')}>
                <ChevronDownImage src={chevronGrayDown} />
            </ScrollToBottomButtonCircleContainer>
        </ScrollToBottomButtonContainer>
    );
}

const ScrollToBottomButtonContainer = styled.div<{ bottomPositionScrollButton: number }>`
    position: fixed;
    bottom: ${({ bottomPositionScrollButton }) => bottomPositionScrollButton}px;
    z-index: 1;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        max-width: 343px;
        min-width: 100%;
        margin-left: 16px;
    }
`;

const ScrollToBottomButtonCircleContainer = styled.div`
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 96px;
    border-width: 1px;
    border-color: #e3e3e3;
    border-style: solid;
    background-color: ${colors.background.default};
    ${CLICKABLE_ELEMENT_CSS_MIXIN}

    @media (max-width: ${deviceWidthMeasurements.small}) {
        width: 26px;
        height: 26px;
    }
`;

const ChevronDownImage = styled.img`
    width: 12px;
    height: 12px;
    object-fit: contain;
`;
